import React, { useEffect, useRef, useState } from 'react'

import { useRouter } from 'next/router'
import { Beforeunload } from 'react-beforeunload'
import Swal from 'sweetalert2'

import { BlockUI } from '@tootz/react-admin/components'

import {
  useResourceRecordContext,
  useSessionContext,
  withResourceRecord
} from '../../../../index'
import { usePaymentsContext } from '../../../../payments'
import showErrorAlert from '../../../../services/showErrorAlert'
import generateCreditCardToken from '../../core/generateCreditCardToken'
import { paymentsApi } from '../../services/api'

import AccountStep from './Steps/Account'
import AddressStep from './Steps/Address'
import BankSlipInInstallmentsStep from './Steps/BankSlipInInstallments'
import ConfirmationStep from './Steps/Confirmation'
import CreditCardStep from './Steps/CreditCard'
import PaymentMethodStep from './Steps/PaymentMethod'

let custom = {
  enterRight: '',
  enterLeft: '',
  exitRight: '',
  exitLeft: ''
}

const PaymentsFormWizard = ({
  payerAddressesConfig,
  payerCreditCardsConfig
}) => {
  const stepWizardRef = useRef(null)
  const router = useRouter()
  const {
    usedId,
    accountId,
    sellingMode,
    cartItems,
    cartIsEmpty,
    emptyCart,
    payer,
    setPayerAddressesConfig,
    setPayerCreditCardsConfig,
    order,
    blocking,
    CREDIT_CARD,
    BANK_SLIP_IN_INSTALLMENTS
  } = usePaymentsContext()
  const { FormWizard, record } = useResourceRecordContext()
  const { user } = useSessionContext()
  const [currentStep, setCurrentStep] = useState(1)

  const handleStepChange = ({ activeStep }) => setCurrentStep(activeStep)
  const goToStep = step => stepWizardRef?.current?.goToStep(step)
  const previousStep = () => stepWizardRef.current.previousStep()
  const nextStep = () => stepWizardRef.current.nextStep()

  const handleSubmit = async data => {
    const newData = {
      ...data,
      selling_mode: sellingMode,
      items: cartItems,
      order_id: order?.id,
      user_id: order?.user_id || user.id
    }
    const { payment_method } = newData

    if (payment_method == CREDIT_CARD) {
      const resCreditCardToken = await generateCreditCardToken(
        newData.credit_card,
        accountId
      )

      newData.credit_card.token = resCreditCardToken
    }

    return await paymentsApi.post(`/accounts/${accountId}/orders`, {
      record: newData
    })
  }

  const handleSave = record => {
    emptyCart()
    router.push(record.return_url)
  }
  const handleError = error => {
    const errorMessage = _.get(error, 'response.data.error', '')

    if (
      errorMessage.includes(
        'operadora financeira recusou o uso do seu cartão de crédito'
      )
    ) {
      Swal.fire({
        title: 'Não autorizado :(',
        icon: 'error',
        html: errorMessage,
        confirmButtonText: 'Alterar meio de pagamento',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      }).then(result => {
        try {
          goToStep(3)
        } catch (error) {}
      })
    } else if (errorMessage.includes('endereço de cobrança não é válido')) {
      Swal.fire({
        title: 'Endereço inválido',
        icon: 'error',
        html: errorMessage,
        confirmButtonText: 'Alterar endereço de cobrança',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      }).then(result => {
        try {
          goToStep(2)
        } catch (error) {}
      })
    } else showErrorAlert(error)
  }

  useEffect(() => {
    if (payerAddressesConfig) setPayerAddressesConfig(payerAddressesConfig)
  }, [payerAddressesConfig])

  useEffect(() => {
    if (payerCreditCardsConfig)
      setPayerCreditCardsConfig(payerCreditCardsConfig)
  }, [payerCreditCardsConfig])

  const getSteps = () => {
    let steps = [AccountStep, AddressStep, PaymentMethodStep]
    if (record?.payment_method == CREDIT_CARD) steps.push(CreditCardStep)
    if (record?.payment_method == BANK_SLIP_IN_INSTALLMENTS)
      steps.push(BankSlipInInstallmentsStep)
    steps.push(ConfirmationStep)
    return steps
  }

  return (
    <BlockUI tag="div" className="h-100" blocking={blocking}>
      {!cartIsEmpty && (
        <Beforeunload onBeforeunload={event => event.preventDefault()} />
      )}

      <FormWizard
        ref={stepWizardRef}
        isHashEnabled={false}
        transitions={custom}
        initialStep={1}
        isLazyMount
        onStepChange={handleStepChange}
        onSubmit={handleSubmit}
        onSave={handleSave}
        onError={handleError}
        defaultValues={{
          payer
        }}
      >
        {getSteps().map((step, index) => {
          const Step = step

          return <Step key={`step-${index}`} />
        })}
      </FormWizard>
    </BlockUI>
  )
}

export default withResourceRecord(PaymentsFormWizard)
