import _ from 'lodash'

const getConfig = (props = {}) => {
  const {} = props
  const filter = {}

  const intervalTypes = [
    { slug: 'hours', title: 'Horas' },
    { slug: 'days', title: 'Dias' },
    { slug: 'weeks', title: 'Semanas' },
    { slug: 'months', title: 'Meses' }
  ]

  const paymentMethods = [
    // { slug: 'all', title: 'Todos' },
    { slug: 'credit_card', title: 'Cartão de Crédito' },
    { slug: 'bank_slip', title: 'Boleto' },
    { slug: 'pix', title: 'PIX' }
  ]

  const config = {
    apiBasePath: '/admin/plans',
    humanName: {
      singular: 'Plano',
      plural: 'Planos'
    },
    filter,
    options: {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refetchInterval: 60000,
      shouldRetryOnError: true
    },
    intervalTypes,
    getIntervalType: slug => _.find(intervalTypes, { slug }),
    paymentMethods,
    getPaymentMethod: slug => _.find(paymentMethods, { slug })
  }
  return config
}

export default getConfig
