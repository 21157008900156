import { useState, useEffect } from 'react'

import axios from 'axios'
import cardValidator from 'card-validator'
import _ from 'lodash'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import RBForm from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useWatch } from 'react-hook-form'

import { useResourceRecordContext, useSessionContext } from '@tootz/react-admin'
import { FormControl } from '@tootz/react-admin/form'
import {
  usePaymentsContext,
  getCartPrice,
  getDiscount
} from '@tootz/react-admin/payments'

import americanExpressSvg from '../../../../../../public/credit-cards/american-express.svg'
import dinersSvg from '../../../../../../public/credit-cards/diners.svg'
import eloSvg from '../../../../../../public/credit-cards/elo.png'
import mastercardSvg from '../../../../../../public/credit-cards/mastercard.svg'
import visaSvg from '../../../../../../public/credit-cards/visa.svg'
import Actions from '../Actions'

const FormBody = ({ goToStep, ...rest }) => {
  const {
    accountId,
    cartPrice,
    paymentMethodsOptions,
    isDefaultMode,
    DEFAULT_MODE_FEE_PERCENT,
    CREDIT_CARD,
    BANK_SLIP,
    PIX
  } = usePaymentsContext()
  const { user } = useSessionContext()

  const { record } = useResourceRecordContext()
  const coupon = useWatch({ name: 'coupon' })
  const [discountStatus, setDiscountStatus] = useState('')
  const [discount, setDiscount] = useState(0)

  const goToAddressStep = () => goToStep(2)
  const goToPaymentMethodStep = () => goToStep(3)

  const { payment_method, installments, credit_card } = record
  const currentPaymentMethodOption = paymentMethodsOptions.find(
    o => o.value == payment_method
  )
  const creditCardValidation = cardValidator.number(
    credit_card?.number.replace(/\D/g, '')
  )
  const creditCardFlags = {
    ['visa']: visaSvg,
    ['mastercard']: mastercardSvg,
    ['american-express']: americanExpressSvg,
    ['diners-club']: dinersSvg,
    ['elo']: eloSvg
  }
  const creditCardFlag = creditCardFlags[creditCardValidation?.card?.type]
  // const cartPrice = getCartPrice(cartItems)
  const subtotalPriceCents = cartPrice.getPaymentMethodCents(
    payment_method,
    installments
  )
  const totalPriceCents = subtotalPriceCents - (discount || 0)
  const installmentsOptions = cartPrice.getInstallmentsOptions(payment_method)

  const handleEnterCupom = async e => {
    if (e.key == 'Enter') {
      e.preventDefault()
      e.stopPropagation()

      await loadDiscount()
    }
  }
  const loadDiscount = async () => {
    if (!coupon) {
      setDiscount(0)
      return
    }

    setDiscountStatus('loading')
    try {
      const discountRes = await axios.post(
        `admin/coupons/${coupon}/get-discount`,
        {
          record: {
            accountId,
            coupon: coupon,
            userId: user.id,
            totalCents: subtotalPriceCents
          }
        }
      )
      const { record } = discountRes.data

      setDiscount(record.cents)

      setDiscountStatus('success')
    } catch (error) {
      console.log(error)
      setDiscountStatus('error')
    }
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="mb-8 text-center">
        <h1 className="mb-3">Confirmação</h1>
        <div className="text-muted fw-bold fs-5">
          Verifique as informações do pedido e finalize
          {JSON.stringify(subtotalPriceCents)}
        </div>
      </div>
      <div className="mb-6">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="fw-bold text-muted">Endereço</span>
          <button
            type="button"
            className="btn btn-sm btn-icon btn-light bg-transparent btn-active-light-primary"
            title="Mudar endereço de cobrança"
            onClick={goToAddressStep}
          >
            <i className="fad fa-edit"></i>
          </button>
        </div>
        <div className="border border-hover-primary p-5 rounded-2 mb-4 cursor-pointer position-relative border-primary">
          <div className="d-flex align-items-center">
            <div>
              <div className="fs-5 fw-bolder d-block text-capitalize mb-1">
                {record.payer.address.street}, {record.payer.address.number}
              </div>
              {record.payer.address.complement && (
                <div className="fs-4 fw-bold text-gray-400 text-capitalize d-block">
                  {record.payer.address.complement}
                </div>
              )}
              <div className="fs-4 fw-bold text-gray-400 text-capitalize d-block">
                {record.payer.address.district}, {record.payer.address.city}/
                {record.payer.address.state}
              </div>
              <div className="fs-5 fw-bolder text-gray-400 d-block">
                {record.payer.address.zip_code}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="fw-bold text-muted">Método de pagamento</span>
          <button
            type="button"
            className="btn btn-sm btn-icon btn-light bg-transparent btn-active-light-primary"
            title="Mudar método de pagamento"
            onClick={goToPaymentMethodStep}
          >
            <i className="fad fa-edit"></i>
          </button>
        </div>
        <div className="border border-hover-primary p-5 pe-10 rounded-2 mb-4 cursor-pointer position-relative border-primary d-flex align-items-center">
          {payment_method === 'credit_card' ? (
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center fs-6 fw-bolder mb-4 lh-1 text-uppercase">
                {_.get(record, 'credit_card.full_name')}
              </div>
              <div className="d-flex align-items-center">
                <img
                  {...creditCardFlag}
                  alt=""
                  className="me-4 h-40px w-60px obj-fit-contain"
                />
                <div>
                  <div className="fs-5 fw-bolder d-block mb-0">
                    {_.get(creditCardValidation, 'card.niceType')} ****{' '}
                    {_.get(record, 'credit_card.number')
                      .trim()
                      .split(' ')
                      .pop()}
                  </div>
                  <div className="fs-4 fw-bold d-block m-0 text-gray-400">
                    Expira em {_.get(record, 'credit_card.expiration')}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="w-40px d-flex align-items-center justify-content-center me-4">
                <i className={`${currentPaymentMethodOption.icon} fs-2qx`}></i>
              </div>
              <div className="d-block fw-bold text-start">
                <span className="text-dark fw-bolder d-block fs-4 d-block">
                  {currentPaymentMethodOption.label}
                </span>
                <span className="text-muted fw-bold fs-6   d-block">
                  {currentPaymentMethodOption.description}
                </span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between mt-auto">
        <div>
          <span className="text-muted d-block mb-2">Cupom</span>
          <InputGroup className="mw-175px">
            <FormControl
              name="coupon"
              disabled={discountStatus == 'loading'}
              className="form-control-solid border-gray-400 form-control-sm"
              onKeyPress={e => handleEnterCupom(e)}
            />
            <span
              className="btn btn-light btn-sm btn-icon cursor-pointer"
              disabled={discountStatus == 'loading'}
              onClick={loadDiscount}
            >
              <i
                className={`fad fa-paper-plane ${
                  discountStatus == 'loading' ? 'fa-spin' : ''
                }`}
              ></i>
            </span>
          </InputGroup>
          <div className="mt-2">
            {discountStatus == 'success' && discount === 0 && (
              <span className="text-danger">Cupom inválido</span>
            )}
            {discountStatus == 'success' && discount > 0 && (
              <span className="text-success">Cupom aplicado com sucesso!</span>
            )}
            {discountStatus == 'error' && (
              <span className="text-danger">
                Ocorreu um erro ao tentar usar o cupom
              </span>
            )}
          </div>
        </div>
        <div className="mw-300px">
          {[BANK_SLIP, PIX].includes(_.get(record, 'payment_method')) ? (
            <>
              {isDefaultMode && (
                <>
                  <div className="mb-4">
                    <div className="d-flex flex-stack mb-2">
                      <div className="fw-bold pe-10 text-gray-600 fs-4">
                        Subtotal:
                      </div>
                      <div className="text-end fw-bolder fs-6 text-gray-700">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(cartPrice.cents / 100)}
                      </div>
                    </div>
                    {cartPrice.inCashCentsDiscount && (
                      <div className="d-flex flex-stack mb-2">
                        <div className="fw-bold pe-10 text-gray-600 fs-4">
                          Desconto à vista:
                        </div>
                        <div className="text-end fw-bolder fs-6 text-success">
                          {cartPrice.inCashCentsDiscountType == 'percent' ? (
                            <>
                              {(cartPrice.inCashCentsDiscount * 100).toFixed(0)}
                              %
                            </>
                          ) : (
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(cartPrice.inCashCentsDiscount / 100)
                          )}
                        </div>
                      </div>
                    )}
                    {!!discount && discount > 0 && (
                      <div className="d-flex flex-stack mb-2">
                        <div className="fw-bold pe-10 text-gray-600 fs-4">
                          Desconto do cupom:
                        </div>
                        <div className="text-end fw-bolder fs-6 text-success">
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(discount / 100)}
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                Regras se aplicam: a) cupons não cumulativos; b)
                                valor mínimo da compra: R$ 5,00.
                              </Tooltip>
                            }
                          >
                            <i
                              className="fad fa-exclamation-circle ms-2"
                              style={{ verticalAlign: 'top' }}
                            ></i>
                          </OverlayTrigger>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="d-flex flex-stack mb-3">
                <div className="fw-bold pe-10 text-gray-600 fs-4">
                  Nº de parcelas:
                </div>
                <div className="text-end fw-bolder fs-6 text-gray-700">
                  {_.get(record, 'installments')}
                </div>
              </div>
              <div className="d-flex flex-stack mb-4">
                <div className="fw-bold pe-10 text-gray-600 fs-4">
                  Valor da parcela:
                </div>
                <div className="text-end fw-bolder fs-6 text-gray-700">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(
                    _.get(
                      _.find(installmentsOptions, {
                        installments
                      }),
                      'priceCents'
                    ) / 100
                  )}
                </div>
              </div>
            </>
          )}

          <div className="d-flex flex-stack">
            <div className="fw-bold pe-10 text-gray-600 fs-5">Total</div>

            <div className="text-end fw-bolder fs-4 text-gray-800">
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(totalPriceCents / 100)}
            </div>
          </div>
        </div>
      </div>

      <Actions {...rest} />
    </div>
  )
}

const ConfirmationStep = ({ Form, ...rest }) => (
  <Form>
    <FormBody {...rest} />
  </Form>
)

export default ConfirmationStep
