import TootzPayments from './modules/payments'
import {
  PaymentsAccountProvider,
  usePaymentsAccountContext,
  withPaymentsAccount
} from './modules/payments/context/PaymentsAccountContext'
import {
  PaymentsProvider,
  usePaymentsContext
} from './modules/payments/context/PaymentsContext'
import {
  constants,
  getTaxes,
  getPrice,
  getCartPrice,
  getSplit,
  getDiscount
} from './modules/payments/core'
import generateCreditCardToken, {
  generateCreditCardTokenV2
} from './modules/payments/core/generateCreditCardToken'
import CheckoutFormWizard from './modules/payments/domain/CheckoutFormWizard'
import Coupons from './modules/payments/domain/Coupons'
import Invoice from './modules/payments/domain/Invoices/Show'
import Plans from './modules/payments/domain/Plans'
import AccountVerifyModal from './modules/payments/domain/Accounts/VerifyModal'
import Subscriptions from './modules/payments/domain/Subscriptions'
import NewSubscriptionModal from './modules/payments/domain/Subscriptions/NewModal'
import Withdrawals from './modules/payments/domain/Withdrawals'
import useCartPrice from './modules/payments/hooks/useCartPrice'
import usePrice from './modules/payments/hooks/usePrice'
import useTaxes from './modules/payments/hooks/useTaxes'
import CheckoutPage from './modules/payments/pages/CheckoutPage'
import InvoicePage from './modules/payments/pages/InvoicePage'
import getAccount from './modules/payments/services/getAccount'

export default TootzPayments
export {
  generateCreditCardToken,
  generateCreditCardTokenV2,
  PaymentsProvider,
  usePaymentsContext,
  PaymentsAccountProvider,
  usePaymentsAccountContext,
  withPaymentsAccount,
  CheckoutFormWizard,
  Invoice,
  Withdrawals,
  Plans,
  Subscriptions,
  NewSubscriptionModal,
  Coupons,
  CheckoutPage,
  InvoicePage,
  constants,
  getTaxes,
  getPrice,
  getCartPrice,
  getSplit,
  getDiscount,
  usePrice,
  useCartPrice,
  useTaxes,
  AccountVerifyModal,
  getAccount
}
