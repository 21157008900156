import React from 'react'

import Actions from '../Actions'

import Fields from './Fields'
import validationSchema from './validationSchema'

const AccountStep = ({ Form, ...rest }) => {
  return (
    <>
      <div className="mb-10 text-center">
        <h1 className="mb-3">Dados pessoais</h1>
        <div className="text-muted fw-bold fs-5">
          Confirme suas informações pessoais e de contato para vinculação do
          pedido
        </div>
      </div>
      <Form validationSchema={validationSchema}>
        <Fields />
        <Actions {...rest} />
      </Form>
    </>
  )
}

export default AccountStep
