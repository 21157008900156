import _ from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toastr from 'toastr'

import { Card, Dropdown, Symbol } from '@tootz/react-admin/components'
import { usePrice } from '@tootz/react-admin/payments'
import { usePaymentsAccountContext } from '@tootz/react-admin/payments'

import getConfig from './config'

const ListItem = ({
  Id,
  MaxUses,
  MaxUsesPerUser,
  ValidUntil,
  Discount,
  DiscountType,
  MaxDiscountCents,
  onEdit
}) => {
  const { accountId } = usePaymentsAccountContext()
  const { getDiscountType } = getConfig()
  const discountType = getDiscountType(DiscountType)

  return (
    <tr>
      <td>
        <CopyToClipboard
          text={Id}
          onCopy={() => toastr.success('Cupom copiado com sucesso', 'Feito!')}
        >
          <span className="fw-bolder text-dark d-block text-hover-primary cursor-pointer">
            {Id}
          </span>
        </CopyToClipboard>
      </td>
      <td className="">{discountType.title}</td>
      <td className="text-end">
        {DiscountType == 'cents'
          ? new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(Discount / 100)
          : `${Discount}%`}
      </td>
      <td className="text-end">
        {MaxDiscountCents
          ? new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(MaxDiscountCents / 100)
          : 'Inl.'}
      </td>
      <td className="text-end">{MaxUses}</td>
      <td className="text-end">{MaxUsesPerUser}</td>
      <td className="">
        {DateTime.fromISO(ValidUntil).toLocaleString(DateTime.DATETIME_MED)}
      </td>
      <td className="text-end">
        <button
          type="button"
          onClick={onEdit}
          className="btn btn-sm btn-light btn-active-primary btn-icon"
        >
          <i className="fad fa-edit text-primary"></i>
        </button>
      </td>
    </tr>
  )
}

export default ListItem
