import _ from 'lodash'
import PropTypes from 'prop-types'
import { color } from 'console-log-colors'

const isDev = process.env.NODE_ENV == 'development'

const ERROR_KEYS = {
  record_invalid: 'Cartão',
  full_name: 'Nome completo',
  number: 'Número',
  expiration: 'Data de expiração',
  verification_value: 'Código de verificação (CVV)'
}

const ERROR_LABELS = {
  'Validation failed: Number is invalid': 'é inválido',
  is_invalid: 'é inválido'
}

const generateCreditCardToken = (
  { full_name, number, expiration, verification_value },
  accountId
) => {
  Iugu.setAccountID(accountId)

  if (isDev || process.env.NEXT_PUBLIC_TEST_MODE) {
    console.info(
      color.blue('[REACT-ADMIN] Payments'),
      'Setting Iugu instance to test mode'
    )

    Iugu.setTestMode(true)
  }

  const creditCard = Iugu.CreditCard(
    number,
    expiration.split('/')[0],
    expiration.split('/')[1],
    full_name.split(' ')[0],
    full_name.split(' ').slice(1).join(' '),
    verification_value
  )

  return new Promise((resolve, reject) => {
    Iugu.createPaymentToken(creditCard, response => {
      if (response.errors) {
        const errorMessage = new Intl.ListFormat('pt-BR').format(
          Object.keys(response.errors).map(key => {
            const label = _.get(ERROR_KEYS, key, key)
            const error = _.get(
              ERROR_LABELS,
              response.errors[key],
              response.errors[key]
            )

            return `${label} ${error}`
          })
        )
        reject({ message: errorMessage })
      } else {
        const { id } = response

        resolve(id)
      }
    })
  })
}

export const generateCreditCardTokenV2 = (
  { full_name, number, expiration, verification_value },
  accountId
) => {
  Iugu.setAccountID(accountId)

  if (isDev || process.env.NEXT_PUBLIC_TEST_MODE) {
    console.info(
      color.blue('[REACT-ADMIN] Payments'),
      'Setting Iugu instance to test mode'
    )

    Iugu.setTestMode(true)
  }

  const creditCard = Iugu.CreditCard(
    number,
    expiration.split('/')[0],
    expiration.split('/')[1],
    full_name.split(' ')[0],
    full_name.split(' ').slice(1).join(' '),
    verification_value
  )

  return new Promise((resolve, reject) => {
    Iugu.createPaymentToken(creditCard, response => {
      if (response.errors) {
        const errorMessage = new Intl.ListFormat('pt-BR').format(
          Object.keys(response.errors).map(key => {
            const label = _.get(ERROR_KEYS, key, key)
            const error = _.get(
              ERROR_LABELS,
              response.errors[key],
              response.errors[key]
            )

            return `${label} ${error}`
          })
        )
        reject({ message: errorMessage })
      } else {
        resolve({
          ...response,
          token: response?.id,
          extraInfo: response?.extra_info
        })
      }
    })
  })
}

generateCreditCardToken.propTypes = {
  full_name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  verification_value: PropTypes.string.isRequired
}

export default generateCreditCardToken
