import { useResourceRecordContext } from '@tootz/react-admin'

const PaymentsFormWizardActions = ({
  currentStep,
  totalSteps,
  previousStep,
  nextStep
}) => {
  const firstStep = currentStep === 1
  const lastStep = currentStep === totalSteps
  const { isSubmitting } = useResourceRecordContext()

  return (
    <div className="d-flex flex-stack pt-10 mt-auto">
      <div>
        {!firstStep && (
          <button
            type="button"
            className={`btn btn-light-primary me-3 ${
              lastStep ? 'btn-icon' : ''
            }`}
            onClick={previousStep}
            disabled={isSubmitting}
          >
            <i className="fad fa-arrow-left"></i>
            {!lastStep && 'Anterior'}
          </button>
        )}
      </div>
      {lastStep ? (
        <button
          type="submit"
          className="btn btn-success flex-grow-1"
          disabled={isSubmitting}
        >
          {!isSubmitting ? (
            <>
              <i className="fad fa-check"></i>
              Finalizar pedido
            </>
          ) : (
            'Finalizando...'
          )}
        </button>
      ) : (
        <button type="submit" className="btn btn-primary">
          Avançar
          <i className="fad fa-arrow-right ms-2 me-0 pe-0"></i>
        </button>
      )}
    </div>
  )
}

export default PaymentsFormWizardActions
