import { useEffect } from 'react'

import { useWatch, useFormContext } from 'react-hook-form'
import store from 'store2'
import { validateCNPJ } from 'validations-br'

import { useResourceRecordContext } from '@tootz/react-admin'
import {
  FormGroup,
  FormControl,
  InputMask,
  BtnGroupRadio,
  PhoneInput
} from '@tootz/react-admin/form'

import { selfApi } from '@/services/api'

const CNPJS_STORE_KEY = 'maestria.cnpjs'
const isDev = process.env.NODE_ENV == 'development'

export const SubscriptionClientFields = () => {
  const {
    record: subscription,
    config: { clientKinds }
  } = useResourceRecordContext()
  const { setValue } = useFormContext()

  const watchRegistrationNumber = useWatch({
    name: 'registration_number',
    defaultValue: subscription?.registration_number
  })
  const watchKind = useWatch({ name: 'kind', defaultValue: subscription?.kind })
  const CNPJ = watchRegistrationNumber
    ? watchRegistrationNumber.replace(/\D/g, '')
    : ''
  const CNPJHas14Digits = CNPJ.length == 14
  const validCNPJ = CNPJHas14Digits && validateCNPJ(watchRegistrationNumber)

  const loadCNPJ = async () => {
    if (watchKind != 'company') return
    if (!CNPJHas14Digits) return
    if (!validCNPJ) return toastr.error('CNPJ inválido')

    try {
      const cnpjsOnStore = store.get(CNPJS_STORE_KEY)
      let cnpjInfo = {}

      if (_.has(cnpjsOnStore, CNPJ)) cnpjInfo = _.get(cnpjsOnStore, CNPJ)
      else {
        const resCNPJ = await selfApi.get(`/cnpj/${CNPJ}`)
        cnpjInfo = _.get(resCNPJ, 'data.record', {})

        try {
          store.add(CNPJS_STORE_KEY, {
            [CNPJ]: cnpjInfo
          })
        } catch (error) {}
      }

      const {
        nome,
        fantasia,
        atividade_principal,
        qsa,
        logradouro,
        cep,
        numero,
        complemento,
        municipio,
        bairro,
        uf,
        telefone
      } = cnpjInfo

      if (isDev) console.log(cnpjInfo)

      setValue('name', nome)
      setValue('alias_name', fantasia)
      setValue('business_type', _.get(atividade_principal, '0.text'))
      setValue('location.zip_code', cep)
      setValue('location.number', numero)
      setValue('location.complement', complemento)
      setValue('location.street', logradouro)
      setValue('location.district', bairro)
      setValue('location.city', municipio)
      setValue('location.state', uf)
      // setValue('slug', _.kebabCase(fantasia))

      if (qsa && qsa.length) setValue('responsible_name', _.get(qsa, '0.nome'))

      // try {
      //   const phones = telefone
      //     .match(/(?:[-+() ]*\d){10,13}/gm)
      //     .map(s => s.trim())

      //   if (phones && phones.length) setValue('phone', _.get(phones, '0'))
      // } catch (error) {}
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadCNPJ()
  }, [watchRegistrationNumber])

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FormGroup
            name="kind"
            label="Tipo de pessoa"
            control={BtnGroupRadio}
            options={clientKinds.map(kind => ({
              label: kind.title,
              value: kind.slug
            }))}
            required
            vertical
          />
        </div>
        <div className="col-md-6">
          {watchKind == 'person' && (
            <FormGroup
              name="registration_number"
              label="CPF"
              control={InputMask}
              mask="999.999.999-99"
              required
              vertical
            />
          )}
          {watchKind == 'company' && (
            <FormGroup
              name="registration_number"
              label="CNPJ"
              control={InputMask}
              mask="99.999.999/9999-99"
              required
              vertical
            />
          )}
        </div>
      </div>
      {watchKind && (
        <FormGroup
          name="name"
          label={watchKind == 'company' ? 'Razão social' : 'Nome Completo'}
          control={FormControl}
          required
          vertical
        />
      )}
      <div className="row">
        <div className="col-md-6">
          <FormGroup
            name="email"
            label="E-mail"
            control={FormControl}
            required
            type="email"
            vertical
          />
        </div>
        <div className="col-md-6">
          <FormGroup
            name="full_phone"
            control={PhoneInput}
            label="Telefone"
            required
            vertical
          />
        </div>
      </div>
    </>
  )
}
