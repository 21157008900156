import _ from 'lodash'
import * as yup from 'yup'

import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'
import { FormGroup, Select } from '@tootz/react-admin/form'

import getConfig from './config'

const schema = yup.object().shape({
  // customerId: yup.string().nullable().required().label('customerId'),
  plan_id: yup.string().nullable().required().label('Plano')
})

const NewSubscriptionModal = ({ accountId, show, onHide, onSave }) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header onHide={onHide}>
        <Modal.Icon className="fad fa-file-signature fa-2x text-primary" />
        <Modal.Title>Adicionar Assinatura</Modal.Title>
      </Modal.Header>
      <Form onSave={onSave} validationSchema={schema}>
        <Modal.Body>
          <FormGroup
            name="plan_id"
            label="Plano"
            control={Select}
            async={{
              href: '/client/plans'
            }}
            vertical
            solid
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            disabled={isSubmitting}
            onClick={onHide}
          >
            Cancelar
          </button>
          <button className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default withResourceRecord(NewSubscriptionModal, getConfig)
