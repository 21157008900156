import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'

import {
  withResourceRecord,
  useResourceRecordContext
} from '@tootz/react-admin'
import { Card, Symbol, Modal } from '@tootz/react-admin/components'
import { AddressFields } from '@tootz/react-admin/form'

const validationSchema = AddressFields.getValidationSchema()

const UserAddressFormModal = ({ show, onHide, onSave }) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.Title>Adicionar Endereço</Modal.Title>
      </Modal.Header>
      <Form onSave={onSave} validationSchema={validationSchema}>
        <Modal.Body>
          <AddressFields showSaveAsDefault />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light mx-2" onClick={onHide}>
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary mx-2"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default withResourceRecord(UserAddressFormModal)
