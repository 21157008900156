import _ from 'lodash'

export const intervalTypes = [
  { slug: 'hours', title: 'Horas', singular: 'Hora' },
  { slug: 'days', title: 'Dias', singular: 'Dia' },
  { slug: 'weeks', title: 'Semanas', singular: 'Semana' },
  { slug: 'months', title: 'Meses', singular: 'Mês' },
  { slug: 'years', title: 'Meses', singular: 'Ano' }
]
export const paymentMethods = [
  { slug: 'credit_card', title: 'Cartão de Crédito' },
  { slug: 'bank_slip', title: 'Boleto' },
  { slug: 'pix', title: 'PIX' }
]

export const getIntervalType = slug => _.find(intervalTypes, { slug })
export const getPaymentMethod = slug => _.find(paymentMethods, { slug })
