import {
  MASTER_KEY,
  DEFAULT_SELLING_MODE,
  MANUAL_SELLING_MODE,
  SELLING_MODES,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  BANK_SLIP,
  PIX,
  ALL_PAYMENT_METHODS,
  MIN_PRICE_CENTS,
  CREDIT_CARD_MIN_INSTALLMENT_PRICE_CENTS,
  BANK_SLIP_MIN_INSTALLMENT_PRICE_CENTS,
  DEFAULT_MODE_FEE_PERCENT,
  DEFAULT_MODE_TAX_CENTS,
  DEFAULT_MODE_TAX_PERCENT,
  IUGU_PIX_TAX_CENTS,
  IUGU_PIX_TAX_PERCENT,
  TTZPAY_PIX_TAX_CENTS,
  TTZPAY_PIX_TAX_PERCENT,
  IUGU_BANK_SLIP_TAX_CENTS,
  IUGU_BANK_SLIP_TAX_PERCENT,
  TTZPAY_BANK_SLIP_TAX_CENTS,
  TTZPAY_BANK_SLIP_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_PERCENTS,
  IUGU_CREDIT_CARD_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_CENTS,
  TTZPAY_CREDIT_CARD_TAX_PERCENT,
  TTZPAY_CREDIT_CARD_TAX_CENTS,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS
} from './config'
import getCartPrice from './getCartPrice'
import getDiscount from './getDiscount'
import getPrice from './getPrice'
import getSplit from './getSplit'
import getTaxes from './getTaxes'

const constants = {
  MASTER_KEY,
  DEFAULT_SELLING_MODE,
  MANUAL_SELLING_MODE,
  SELLING_MODES,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  BANK_SLIP,
  PIX,
  ALL_PAYMENT_METHODS,
  MIN_PRICE_CENTS,
  CREDIT_CARD_MIN_INSTALLMENT_PRICE_CENTS,
  BANK_SLIP_MIN_INSTALLMENT_PRICE_CENTS,
  DEFAULT_MODE_FEE_PERCENT,
  DEFAULT_MODE_TAX_CENTS,
  DEFAULT_MODE_TAX_PERCENT,
  IUGU_PIX_TAX_CENTS,
  IUGU_PIX_TAX_PERCENT,
  TTZPAY_PIX_TAX_CENTS,
  TTZPAY_PIX_TAX_PERCENT,
  IUGU_BANK_SLIP_TAX_CENTS,
  IUGU_BANK_SLIP_TAX_PERCENT,
  TTZPAY_BANK_SLIP_TAX_CENTS,
  TTZPAY_BANK_SLIP_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_PERCENTS,
  IUGU_CREDIT_CARD_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_CENTS,
  TTZPAY_CREDIT_CARD_TAX_PERCENT,
  TTZPAY_CREDIT_CARD_TAX_CENTS,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS
}

export { constants, getTaxes, getPrice, getCartPrice, getSplit, getDiscount }
