import _ from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Card, Dropdown, Symbol } from '@tootz/react-admin/components'
import { usePrice } from '@tootz/react-admin/payments'
import { usePaymentsAccountContext } from '@tootz/react-admin/payments'

import getConfig from './config'

const ListItem = ({
  id,
  billingDays,
  createdAt,
  features,
  name,
  identifier,
  interval,
  intervalType,
  maxCycles,
  payableWith,
  priceCents,
  updatedAt,
  onEdit
}) => {
  const { accountId } = usePaymentsAccountContext()
  const { getIntervalType, getPaymentMethod } = getConfig()
  const intervalTypeObj = getIntervalType(intervalType)

  return (
    <tr>
      <td>
        <span className="fw-bolder text-dark d-block">{name}</span>
        <span>{identifier}</span>
      </td>
      <td>
        {payableWith &&
          payableWith.map(paymentMethod => (
            <span key={paymentMethod} className="fs-4 d-block">
              {getPaymentMethod(paymentMethod)?.title}
            </span>
          ))}
      </td>
      <td className="">
        <span className="fs-5 d-block">a cada</span>
        {interval} {intervalTypeObj?.title}
      </td>
      <td>{maxCycles || 'Indeterminado'}</td>
      <td className="text-end">{billingDays}</td>
      <td className="text-end">
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(priceCents / 100)}
      </td>
      <td className="text-end">
        <button
          type="button"
          onClick={onEdit}
          className="btn btn-sm btn-light btn-active-primary btn-icon"
        >
          <i className="fad fa-edit text-primary"></i>
        </button>
      </td>
    </tr>
  )
}

export default ListItem
