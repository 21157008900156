import _ from 'lodash'
import PropTypes from 'prop-types'

import { Card } from '../../../components'
import { usePaymentsContext } from '../context/PaymentsContext'

const PaymentsCheckoutPage = ({ ...rest }) => {
  const { orderUUID, cartItems, cartTotalPriceCents } = usePaymentsContext()

  return (
    <div className="row py-10">
      <div className="col-12 d-flex align-items-center mb-6">
        <i className="fad fa-arrow-left"></i>
        <span className="fw-bold text-muted ms-4">Maestrho</span>
      </div>
      <div className="col-md-6 mb-6 mb-md-0">
        <Card>
          <Card.Body>
            <div className="d-flex mb-10">
              <div className="fv-row">
                <label className="form-label">ID da Ordem</label>
                <div className="fw-bolder fs-3">#{orderUUID?.substr(1, 7)}</div>
              </div>
              <div className="fv-row ms-10">
                <label className="form-label">Valor</label>
                <div className="fw-bolder fs-3">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(cartTotalPriceCents / 100)}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              {cartItems.map(item => (
                <div
                  key={item.id}
                  className="d-flex align-items-center border border-dashed p-3 rounded bg-white w-100 mb-6"
                >
                  <a href="x" className="symbol symbol-50px">
                    <span
                      className="symbol-label"
                      style={{
                        backgroundImage: 'url(https://picsum.photos/200/300)'
                      }}
                    ></span>
                  </a>

                  <div className="ms-5">
                    <a
                      href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/edit-product.html"
                      className="text-gray-800 text-hover-primary fs-5 fw-bolder"
                    >
                      {item.description}
                    </a>

                    <div className="fw-bold fs-4">
                      Preço:{' '}
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(item.priceCents / 100)}
                    </div>

                    <div className="text-muted fs-4">Qtd.: {item.quantity}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center">
              <span className="text-gray-400 fs-5">
                <span>Powered By</span>
                <span className="fw-bolder ms-1">Tootz Pagamentos</span>
              </span>
              <span className="text-gray-400 fs-5 border-start ps-4 ms-4">
                <span>Termos & Política de Privacidade</span>
              </span>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-md-6">
        <Card>
          <Card.Header noBorder>
            <Card.Title className="fw-bolder">Pagamento</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0"></Card.Body>
        </Card>
      </div>
    </div>
  )
}

PaymentsCheckoutPage.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      priceCents: PropTypes.number.isRequired
    })
  )
}

export default PaymentsCheckoutPage
