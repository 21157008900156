import _ from 'lodash'

import { usePaymentsAccountContext } from '@tootz/react-admin/payments'

const useConfig = (props = {}) => {
  const { accountId } = usePaymentsAccountContext()
  const {} = props
  const filter = {}

  const status = [
    { slug: 'pending', title: 'Pendente', variant: 'warning' },
    { slug: 'processing', title: 'Processando', variant: 'primary' },
    { slug: 'accepted', title: 'Aceito', variant: 'success' },
    { slug: 'refused', title: 'Recusado', variant: 'danger' }
  ]

  const config = {
    apiBasePath: 'admin/withdrawals',
    filter,
    options: {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refetchInterval: 60000,
      shouldRetryOnError: true
    },
    status,
    getStatus: slug => _.find(status, { slug })
  }
  return config
}

export default useConfig
