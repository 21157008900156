import { BANK_SLIP_IN_INSTALLMENTS } from '../config'

const _calcMaxInstallments = (
  priceCents,
  minInstallmentPriceCents,
  paymentMethodMaxInstallments,
  paymentMethod
) => {
  if (priceCents < minInstallmentPriceCents) return 0

  const maxInstallments = Math.min(
    Math.floor(priceCents / minInstallmentPriceCents),
    paymentMethodMaxInstallments
  )

  if (paymentMethod == BANK_SLIP_IN_INSTALLMENTS && maxInstallments == 1)
    return 0

  return maxInstallments
}

export default _calcMaxInstallments
