import _ from 'lodash'
import * as Sentry from '@sentry/nextjs'

import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import getConfig from '../config'

import VerifyAccountModalFields from './Fields'

const VerifyAccountModal = ({ show, type, cnpj, onHide, onSave }) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  const handleBeforeSave = data => {
    const newData = {
      ...data,
      person_type: 'Pessoa Jurídica',
      price_range: 'Mais que R$ 500,00',
      automatic_transfer: true,
      physical_products: true,
      address: `${data.street}, ${data.number}`
    }

    try {
      Sentry.captureMessage(
        `Verifing sub account ${JSON.stringify(newData)}`,
        'log'
      )
    } catch (error) {}

    return newData
  }

  return (
    <Modal
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onHide}
    >
      <Modal.Header onHide={onHide}>
        <Modal.Icon className="fad fa-circle-check fa-2x text-primary" />
        <Modal.Title>Pré-cadastro de Conta</Modal.Title>
        <Modal.Subtitle>
          Preencha corretamente as informações da sua empresa, dados incorretos
          poderão impedir a configuração de venda junto à operadora financeira
        </Modal.Subtitle>
      </Modal.Header>
      <Form customAction="verify" beforeSave={handleBeforeSave} onSave={onSave}>
        <Modal.Body>
          <VerifyAccountModalFields type={type} cnpj={cnpj} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            disabled={isSubmitting}
            onClick={onHide}
          >
            Cancelar
          </button>
          <button className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default withResourceRecord(VerifyAccountModal, getConfig)
