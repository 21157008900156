import { useState, useRef } from 'react'

import _ from 'lodash'
import Swal from 'sweetalert2'
import { Button } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ReCAPTCHA from 'react-google-recaptcha'

import { Overlay } from '../../components/Overlay'
import { useAuthContext } from '../context'

import { SignUpFormFields } from './Fields'
import { SignUpProviders } from './Providers'

const isDev = process.env.NODE_ENV === 'development'

const generateFieldValues = () => {
  const email = `wendell+${(Math.random() + 1)
    .toString(36)
    .substring(7)}@tootz.com.br`

  return {
    name: 'Wendell Adriel',
    email,
    email_confirmation: email,
    password: '123123123',
    password_confirmation: '123123123'
  }
}

export const SignUpForm = ({ onSignUp, onError, withTerms, children }) => {
  const reCaptchaRef = useRef()
  const {
    httpClient,
    styleConfig,
    goToSignIn,
    goToConfirmation,
    handleSignIn,
    setCurrentEmail,
    callbackUrl,
    reCaptchaEnabled,
    useReCaptchaV3,
    reCaptchaKey,
    config
  } = useAuthContext()
  const withEmailConfirmation = _.get(
    config,
    'signUp.withEmailConfirmation',
    false
  )
  const header =
    _.has(config, 'signUp.header') && _.get(config, 'signUp.header', {})
  const skipConfirmation = _.get(config, 'signUp.skipConfirmation', false)
  const hideSignInAction = _.get(config, 'signUp.hideSignInAction', false)
  const methods = useForm({
    defaultValues: isDev ? generateFieldValues() : {},
    resolver: yupResolver(
      SignUpFormFields.validationSchema({ withTerms, withEmailConfirmation })
    )
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSignUp = async data => {
    if (isSubmitting) return

    setIsSubmitting(true)

    try {
      if (reCaptchaEnabled) {
        let recaptchaValue

        if (useReCaptchaV3)
          recaptchaValue = await reCaptchaRef.current.execute()
        else recaptchaValue = reCaptchaRef.current.getValue()

        if (!recaptchaValue) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Antes de entrar, use o reCAPTCHA pra verificar sua identidade'
          })

          setIsSubmitting(false)
          return
        }
      }

      const { name, email, password, password_confirmation } = data

      setCurrentEmail(email)

      await httpClient.post('/auth/sign-up', {
        ...data,
        name,
        email,
        password,
        password_confirmation
      })

      if (skipConfirmation) {
        if (typeof onSignUp !== 'undefined' && _.isFunction(onSignUp))
          onSignUp({ email, password })
        else {
          const { ok, error } = await handleSignIn({
            login: email,
            password
          })

          if (ok) return window.location.replace(callbackUrl)
          if (error) goToSignIn()
        }
      } else goToConfirmation({ email, password })
    } catch (error) {
      if (typeof onError !== 'undefined' && _.isFunction(onError))
        onError({ error })
      else {
        methods.setError('email', {
          type: 'custom',
          message: 'Email já está em uso'
        })
      }
    }

    setIsSubmitting(false)
  }

  return (
    <Overlay
      block
      active={isSubmitting}
      opacity={50}
      layer={
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      }
    >
      {header && (
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">{header?.title}</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            {header?.subtitle}
          </div>
        </div>
      )}

      <SignUpProviders />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSignUp)}>
          <SignUpFormFields withTerms={withTerms}>{children}</SignUpFormFields>

          {reCaptchaEnabled && (
            <div
              className={`d-flex align-items-center justify-content-center w-100 ${
                useReCaptchaV3 ? '' : 'mb-6'
              }`}
            >
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={reCaptchaKey}
                size={useReCaptchaV3 ? 'invisible' : 'normal'}
              />
            </div>
          )}

          {!!withTerms &&
            _.isObject(withTerms) &&
            _.get(withTerms, 'autoAgree') && (
              <div className="mb-6">
                <label className="text-muted fs-5 text-center">
                  Ao prosseguir, você concorda com os{' '}
                  <a
                    href={withTerms.href}
                    target="_blank"
                    rel="noreferrer"
                    className="fw-bold"
                  >
                    Termos de Uso{' '}
                    <span className="fw-normal"> (clique para ler)</span>
                  </a>{' '}
                  da plataforma
                </label>
              </div>
            )}

          <div className="d-grid mb-10 mt-8">
            <Button
              type="submit"
              variant="primary"
              size={styleConfig.size}
              solid={styleConfig.solid ? true : undefined}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span>
                    <i className="fad fa-spinner-third fa-spin"></i>
                  </span>{' '}
                  Criando conta...
                </>
              ) : (
                'Criar conta'
              )}
            </Button>
          </div>

          {!hideSignInAction && (
            <div className="text-gray-500 text-center fw-semibold fs-6 d-flex justify-content-center">
              Já possui uma conta?
              <button
                type="button"
                className="btn btn-flush link-primary fs-6 fw-semibold ms-1"
                onClick={goToSignIn}
              >
                Faça login
              </button>
            </div>
          )}
        </form>
      </FormProvider>
    </Overlay>
  )
}
