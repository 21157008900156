import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import toastr from 'toastr'
import { DateTime } from 'luxon'
import CopyToClipboard from 'react-copy-to-clipboard'

import { Card } from '../../../../components'

import getConfig from './config'

const Invoice = ({ id }) => {
  const [invoice, setInvoice] = useState(null)

  const { getStatus, getPaymentMethod } = getConfig()

  useEffect(() => {
    const loadInvoice = async () => {
      axios
        .get(`/api/payments/invoices/${id}`)
        .then(response => {
          setInvoice(response.data)
        })
        .catch(error => console.log(error.response.data))
    }

    loadInvoice()
  }, [id])

  if (!invoice) return <></>

  const status = getStatus(invoice.status)
  const paymentMethod = getPaymentMethod(invoice.payable_with)
  const waitingPayment = ['pending', 'partially_paid'].includes(invoice.status)

  return (
    <Card className="p-lg-20">
      <Card.Body className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
          <div className="mt-n1">
            <div className="d-flex flex-stack pb-10">
              <a href="#">
                <img
                  alt="Logo"
                  src="/metronic8/demo1/assets/media/svg/brand-logos/code-lab.svg"
                />
              </a>
              {waitingPayment && (
                <a
                  href={`${invoice.secure_url}.pdf`}
                  target="_blank"
                  className="btn btn-sm btn-success"
                  rel="noreferrer"
                >
                  Pagar agora
                </a>
              )}
            </div>

            <div className="m-0">
              <div className="fw-bolder fs-3 text-gray-800 mb-8">
                Fatura #{invoice.id}
              </div>
              <div className="row g-5 mb-11">
                <div className="col-sm-6">
                  <div className="fw-bold fs-4 text-gray-600 mb-1">Data:</div>
                  <div className="fw-bolder fs-6 text-gray-800">
                    {new DateTime(invoice.created_at_iso).toLocaleString(
                      DateTime.DATE_MED
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="fw-bold fs-4 text-gray-600 mb-1">
                    Vencimento:
                  </div>
                  <div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                    <span className="pe-2">
                      {new DateTime(invoice.due_date).toLocaleString(
                        DateTime.DATE_MED
                      )}
                    </span>
                    <span className="fs-4 text-danger d-flex align-items-center">
                      <span className="bullet bullet-dot bg-danger me-2"></span>
                      Due in 7 days
                    </span>
                  </div>
                </div>
              </div>
              {invoice.payable_with === 'bank_slip' && waitingPayment && (
                <>
                  <div className="mb-11">
                    <div className="fw-bold fs-4 text-gray-600 mb-1">
                      Linha digitável:
                    </div>
                    <div className="fw-bolder fs-6 text-gray-800">
                      {invoice.bank_slip.digitable_line}
                    </div>
                  </div>
                  <div className="mb-11">
                    <div className="fw-bold fs-4 text-gray-600 mb-1">
                      Código de barrras:
                    </div>
                    <img src={invoice.bank_slip.barcode} alt="" className="" />
                  </div>
                </>
              )}
              <div className="flex-grow-1">
                <div className="table-responsive border-bottom mb-9">
                  <table className="table mb-3">
                    <thead>
                      <tr className="border-bottom fs-6 fw-bolder text-muted">
                        <th className="min-w-175px pb-2">Descrição</th>
                        <th className="min-w-80px text-end pb-2">
                          Valor Unit.
                        </th>
                        <th className="min-w-70px text-end pb-2">Qtd.</th>
                        <th className="min-w-100px text-end pb-2">
                          Valot Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.items.map(item => (
                        <tr
                          key={item.id}
                          className="fw-bolder text-gray-700 fs-5 text-end"
                        >
                          <td className="d-flex align-items-center pt-6">
                            <i className="fa fa-genderless text-primary fs-2 me-2"></i>
                            {item.description}
                          </td>
                          <td className="pt-6">{item.price}</td>
                          <td className="pt-6">{item.quantity}</td>
                          <td className="pt-6 text-dark fw-boldest">
                            {item.price}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="mw-300px">
                    {/* <div className="d-flex flex-stack mb-3">
                      <div className="fw-bold pe-10 text-gray-600 fs-4">
                        Subtotal:
                      </div>
                      <div className="text-end fw-bolder fs-6 text-gray-800">
                        $ 20,600.00
                      </div>
                    </div>
                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-bold pe-10 text-gray-600 fs-4">
                        VAT 0%
                      </div>
                      <div className="text-end fw-bolder fs-6 text-gray-800">
                        0.00
                      </div>
                    </div>
                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-bold pe-10 text-gray-600 fs-4">
                        Subtotal + VAT
                      </div>
                      <div className="text-end fw-bolder fs-6 text-gray-800">
                        $ 20,600.00
                      </div>
                    </div> */}
                    <div className="d-flex flex-stack">
                      <div className="fw-bold pe-10 text-gray-600 fs-4">
                        Total
                      </div>
                      <div className="text-end fw-bolder fs-6 text-gray-800">
                        {invoice.total}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-0">
          <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
            <div className="mb-8">
              {status && (
                <span
                  className={`badge badge-light-${status.variant} text-capitalize me-2`}
                >
                  {status.name}
                </span>
              )}
            </div>

            <h6 className="mb-8 fw-boldest text-gray-600 text-hover-primary text-uppercase">
              Detalhes do pagamento
            </h6>
            <div className="mb-6">
              <div className="fw-bold text-gray-600 fs-4">E-mail:</div>
              <div className="fw-bolder text-gray-800 fs-6">
                {invoice.email}
              </div>
            </div>
            <div className="mb-6">
              <div className="fw-bold text-gray-600 fs-4">Método:</div>
              <div className="fw-bolder text-gray-800 fs-6">
                {paymentMethod.name}
              </div>
            </div>
            {invoice.payable_with === 'pix' && waitingPayment && (
              <>
                <div className="mb-6">
                  <div className="fw-bold text-gray-600 fs-4">QR Code:</div>
                  <img
                    src={invoice.pix.qrcode}
                    alt=""
                    className="mw-300px d-block"
                  />
                  <CopyToClipboard
                    text={invoice.pix.qrcode_text}
                    onCopy={() => {
                      toastr.success(
                        'Chave de pagamento copiada com sucesso',
                        'Pronto'
                      )
                    }}
                  >
                    <button className="btn btn-sm btn-outline btn-outline-dark d-block w-100">
                      Copiar chave de pagamento
                    </button>
                  </CopyToClipboard>
                </div>
              </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

Invoice.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default Invoice
