import _ from 'lodash'
import { useRouter } from 'next/router'
import { Wizard } from 'react-use-wizard'
import toastr from 'toastr'

import {
  useResourceRecordContext,
  withResourceRecord,
  useRecords
} from '@tootz/react-admin'
import { Modal, LoadWrapper } from '@tootz/react-admin/components'
import { generateCreditCardTokenV2 } from '@tootz/react-admin/payments'

import { getConfig } from './config'
import { SubscriptionFields } from './Fields'
import { SubscriptionsPlanFields } from './Fields/Plan'

export const SubscriptionModal = withResourceRecord(
  ({ blocking, downgrade, trial = true, show, onHide, onSave }) => {
    const router = useRouter()
    const { record: subscription, Form } = useResourceRecordContext()
    const { records: plans, loadWrapperConfig } = useRecords('/client/plans')
    const currentPlan = _.get(subscription, 'plan')
    const upgrade = !!currentPlan && !downgrade
    const availablePlans = plans

    const handleHide = () => {
      onHide()
    }
    const handleBeforeSave = async data => {
      if (!data.plan_id) throw new Error('No Plan Found')

      const { plan_is_paid } = data

      if (plan_is_paid) {
        const { token, extraInfo } = await generateCreditCardTokenV2(
          data,
          process.env.NEXT_PUBLIC_TOOTZ_PAYMENTS_CLIENT_ACCOUNT_ID
        )

        return {
          default_payment_method: 'credit_card',
          token: token,
          ttzpay_credit_card_fullname: extraInfo.full_name,
          ttzpay_credit_card_number: extraInfo.display_number,
          ttzpay_credit_card_expiration: data.expiration,
          ttzpay_credit_card_brand: extraInfo.brand,
          ..._.omit(data, [
            'number',
            'full_name',
            'expiration',
            'verification_value'
          ])
        }
      } else return { ...data }
    }

    const handleSave = record => {
      toastr.success('Sua assinatura está ativa!', 'Feito!')
      onSave(record)
    }

    return (
      <LoadWrapper {...loadWrapperConfig} fullscreen>
        <Modal
          centered
          show={show}
          onHide={handleHide}
          backdrop={blocking ? 'static' : true}
          keyboard={!blocking}
          contentClassName="bg-white bg-opacity-75"
          size="lg"
        >
          <Form
            onSave={handleSave}
            beforeSave={handleBeforeSave}
            validationSchema={SubscriptionFields.validationSchema(subscription)}
            customAction={{
              name: '',
              method: subscription ? 'put' : 'post',
              default_payment_method: 'credit_card'
            }}
          >
            <Wizard>
              <SubscriptionsPlanFields
                availablePlans={availablePlans}
                downgrade={downgrade}
                upgrade={upgrade}
                currentPlan={currentPlan}
                onHide={blocking ? null : handleHide}
              />
              <SubscriptionFields onHide={blocking ? null : handleHide} />
            </Wizard>
          </Form>
        </Modal>
      </LoadWrapper>
    )
  },
  getConfig
)
