import { getIntervalType } from './config'

export const PlansTablePricing = ({
  plans = [],
  currentPlan,
  handleSelect
}) => {
  return (
    <div class="table-responsive">
      <table class="table align-middle table-striped gy-5">
        <thead class="align-middle">
          <tr id="ttz_pricing">
            <th>
              <div className="text-gray-600 fw-semibold mb-5 fs-5">
                A mais moderna tecnologia de busca (Semântica): encontre
                melhores decisões em menor tempo
              </div>
            </th>

            {plans.map(plan => {
              const hasDiscountPrice =
                plan.ttzpay_discount_interval > 0 &&
                plan.ttzpay_price_with_discount_cents > 0

              return (
                <th key={plan.id} class="text-center min-w-200px">
                  <div className="mb-10">
                    <div class="fs-3 fw-bold mb-7">{plan.name}</div>

                    <div
                      class={`${
                        hasDiscountPrice
                          ? 'fs-1 text-decoration-line-through text-gray-600'
                          : 'fs-3x text-primary'
                      } fw-semibold d-flex justify-content-center align-items-start lh-sm `}
                    >
                      <span
                        class={`align-self-start ${
                          hasDiscountPrice ? 'fs-9 mt-1' : 'fs-4 mt-3'
                        }`}
                      >
                        R$
                      </span>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        currencyDisplay: 'code',
                        maximumFractionDigits: 0
                      })
                        .format(plan.ttzpay_price_cents / 100)
                        .replace('BRL', '')
                        .trim()}
                    </div>
                    {hasDiscountPrice && (
                      <div class="fs-3x fw-semibold d-flex justify-content-center align-items-start lh-sm text-primary">
                        <span class="align-self-start fs-4 mt-3">R$</span>
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          currencyDisplay: 'code',
                          maximumFractionDigits: 0
                        })
                          .format(plan.ttzpay_price_with_discount_cents / 100)
                          .replace('BRL', '')
                          .trim()}
                      </div>
                    )}

                    {hasDiscountPrice ? (
                      <div class="text-muted fw-bold mb-7 fs-5">
                        {`no${plan.ttzpay_discount_interval > 1 && 's'} ${
                          plan.ttzpay_discount_interval
                        } primeiros ${getIntervalType(
                          plan.ttzpay_interval_type
                        )?.[
                          plan.ttzpay_discount_interval > 1
                            ? 'title'
                            : 'singular'
                        ].toLowerCase()}`}
                      </div>
                    ) : (
                      <div class="text-muted fw-bold mb-7 fs-5">
                        {!plan.ttzpay_is_trial && plan.ttzpay_price_cents > 0
                          ? `ao ${getIntervalType(
                              plan.ttzpay_interval_type
                            )?.singular.toLowerCase()}`
                          : `por ${plan.ttzpay_interval} ${getIntervalType(
                              plan.ttzpay_interval_type
                            )?.singular.toLowerCase()}`}
                      </div>
                    )}

                    {currentPlan && currentPlan.id == plan.id ? (
                      <span className="btn btn-sm bg-gray-300">
                        Plano Ativo
                      </span>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => handleSelect(plan)}
                      >
                        Selecionar
                      </button>
                    )}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody>
          {[
            'Busca semântica',
            'Filtros inteligentes',
            'Inteiro teor na tela',
            'Indicação inteligente de Súmulas e Repercussão Geral',
            'Estatísticas'
          ].map((benefit, index) => (
            <tr key={index}>
              <th class="card-rounded-start">
                <div class="fw-bold d-flex align-items-center ps-9 fs-4">
                  {benefit}
                </div>
              </th>

              <td>
                <div class="flex-root d-flex justify-content-center">
                  <span class="h-40px w-40px d-flex flex-center d-block">
                    <i class="fa-solid fa-check fs-2x text-success"></i>
                  </span>
                </div>
              </td>

              <td class="card-rounded-end">
                <div class="flex-root d-flex justify-content-center">
                  <span class="h-40px w-40px d-flex flex-center d-block">
                    <i class="fa-solid fa-check fs-2x text-success"></i>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
