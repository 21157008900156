import _ from 'lodash'

const useConfig = (props = {}) => {
  const {} = props
  const filter = {}

  const discountTypes = [
    { slug: 'percent', title: 'Porcentagem' },
    { slug: 'cents', title: 'Valor' }
  ]

  const paymentMethods = [
    // { slug: 'all', title: 'Todos' },
    { slug: 'credit_card', title: 'Cartão de Crédito' },
    { slug: 'bank_slip', title: 'Boleto' },
    { slug: 'pix', title: 'PIX' }
  ]

  const config = {
    apiBasePath: 'admin/coupons',
    humanName: {
      singular: 'Cupom',
      plural: 'Cupons'
    },
    filter,
    options: {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refetchInterval: 60000,
      shouldRetryOnError: true
    },
    discountTypes,
    getDiscountType: slug => _.find(discountTypes, { slug }),
    paymentMethods,
    getPaymentMethod: slug => _.find(paymentMethods, { slug })
  }
  return config
}

export default useConfig
