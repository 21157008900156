import { validateCPF, validateCNPJ } from 'validations-br'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

const schema = yup.object().shape({
  payer: yup.object().shape({
    name: yup
      .string()
      .nullable()
      .test(
        'is-valid-name',
        'Não pode conter abreviações',
        value =>
          !value
            .split(' ')
            .find(l => l.length == 1 || (l.length == 2 && l.includes('.')))
      )
      .required()
      .label('Nome'),
    email: yup.string().nullable().email().required().label('E-mail'),
    cpf_cnpj: yup
      .string()
      .test('is-cpf', 'Não é um CPF válido', (value, { parent }) =>
        validateCPF(value)
      )
      .required()
      .label('CPF'),
    phone_prefix: yup.string().nullable().required().label('DDD'),
    phone: yup.string().nullable().required().label('Telefone')
  })
})

export default schema
