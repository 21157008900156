import { useResourceRecordContext } from '@tootz/react-admin'
import {
  FormGroup,
  FormControl,
  Select,
  AddressFields,
  PaymentMethodField
} from '@tootz/react-admin/form'
import {
  usePaymentsContext,
  usePaymentsAccountContext
} from '@tootz/react-admin/payments'

const Fields = ({ accountId, customerId }) => {
  // const { accountId } = useResourceRecordContext()

  return (
    <>
      <FormControl name="customerId" value={customerId} />
      <FormGroup
        name="subscription.planId"
        label="Plano"
        control={Select}
        async={{
          href: 'admin/plans'
        }}
        vertical
        solid
        required
      />
      {/* <FormGroup
        name="subscription.clientId"
        label="ID do Cliente"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="client.name"
        label="Nome do Cliente"
        type="email"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="client.email"
        label="E-mail do Cliente"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="client.notes"
        label="Informações Adicionais"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="client.phone_prefix"
        label="Prefixo do Telefone"
        control={FormControl}
        type="number"
        solid
        required
      />
      <FormGroup
        name="client.phone"
        label="Telefone"
        control={FormControl}
        type="number"
        solid
        required
      />
      <FormGroup
        name="client.cpf_cnpj"
        label="CPF/CNPJ"
        control={FormControl}
        solid
        required
      />
      <AddressFields namePrefix="client" horizontal />
      <FormGroup
        name="payment_method"
        label="Método de pagamento"
        vertical
        required
      >
        <PaymentMethodField
          name="payment_method"
          label="Método de pagamento"
          vertical
        />
      </FormGroup> */}
    </>
  )
}

export default Fields
