import _ from 'lodash'
import { useWatch } from 'react-hook-form'

import {
  FormGroup,
  FormControl,
  Select,
  MoneyInput,
  DateInput
} from '@tootz/react-admin/form'

import getConfig from '../config'

const Fields = () => {
  const { discountTypes } = getConfig()
  const watchDiscountType = useWatch({ name: 'DiscountType' })

  return (
    <>
      <FormGroup
        name="Id"
        label="Código"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="MaxUses"
        label="Nº máximo de usos"
        control={FormControl}
        type="number"
        min={0}
        defaultValue={0}
        helpText="Utilize o valor 0 para usos ilimitados"
        solid
        required
      />
      <FormGroup
        name="MaxUsesPerUser"
        label="Nº máximo de usos por pessoa"
        control={FormControl}
        type="number"
        min={0}
        defaultValue={0}
        helpText="Utilize o valor 0 para usos ilimitados por pessoa"
        solid
        required
      />
      <FormGroup
        name="ValidUntil"
        label="Valido até"
        control={DateInput}
        withTime
        solid
        required
      />
      <FormGroup
        name="DiscountType"
        label="Tipo de desconto"
        control={Select}
        options={discountTypes.map(opt => ({
          label: opt.title,
          value: opt.slug
        }))}
        solid
        required
      />
      {watchDiscountType == 'percent' && (
        <FormGroup
          name="Discount"
          label="Porcentagem"
          control={FormControl}
          type="number"
          min={0}
          max={100}
          solid
          required
        />
      )}
      {watchDiscountType == 'cents' && (
        <FormGroup
          name="Discount"
          label="Valor"
          control={MoneyInput}
          solid
          required
        />
      )}
      <FormGroup
        name="MaxDiscountCents"
        label="Valor máximo por cupom"
        control={MoneyInput}
        helpText="Utilize o valor 0 para sem valor maxímo"
        solid
        required
      />
    </>
  )
}

export default Fields
