import Link from 'next/link'

import { Modal } from '@tootz/react-admin/components'

import { useSubscriptionContext } from '@/context/subscription'

export const SubscribeNowModal = () => {
  const { isTrial, subscription } = useSubscriptionContext()

  return (
    <Modal
      centered
      show
      backdrop={'static'}
      keyboard
      contentClassName="bg-white bg-opacity-75"
    >
      <Modal.Header>
        <Modal.Title className="fw-bold">
          {!isTrial && !subscription && 'Seu período de teste expirou!'}
          {!isTrial && subscription && expired && 'Sua assinatura expirou!'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 text-center">
        Assine agora mesmo e continue aproveitando todos os benefícios!
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-primary" href="/assinatura/checkout">
          Assinar agora
        </Link>
      </Modal.Footer>
    </Modal>
  )
}
