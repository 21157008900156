import _ from 'lodash'

import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import Fields from './Form/Fields'
import getConfig from './config'

const EditModal = ({ show, onHide, onSave }) => {
  const { Form, record, isSubmitting } = useResourceRecordContext()

  return (
    <Modal centered size="xl" show={show} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.Icon className="fad fa-tally-3 fa-2x text-primary" />
        <Modal.Title>Editar Plano</Modal.Title>
        <Modal.Subtitle>{record?.name}</Modal.Subtitle>
      </Modal.Header>
      <Form onSave={onSave}>
        <Modal.Body>
          <Fields />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            disabled={isSubmitting}
            onClick={onHide}
          >
            Cancelar
          </button>
          <button className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default withResourceRecord(EditModal, getConfig)
