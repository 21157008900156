import { useEffect } from 'react'

import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import { validateCNPJ } from 'validations-br'
import axios from 'axios'
import toastr from 'toastr'

import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import {
  FormGroup,
  FormControl,
  FormRadio,
  Select,
  InputMask,
  AddressFields,
  FormCheck
} from '@tootz/react-admin/form'

import getConfig from '../config'

const VerifyAccountModalFields = () => {
  const { priceRanges, banks, getBank } = getConfig()
  const { watch, setValue } = useFormContext()

  const watchCNPJ = watch('cnpj')
  const watchBank = watch('bank')

  const bank = getBank(watchBank)

  const CNPJ = watchCNPJ ? watchCNPJ.replace(/\D/g, '') : ''
  const CNPJHas14Digits = CNPJ.length == 14
  const validCNPJ = CNPJHas14Digits && validateCNPJ(watchCNPJ)

  useEffect(() => {
    if (CNPJHas14Digits) {
      if (validCNPJ) {
        axios
          .get(`${process.env.NEXT_PUBLIC_PAYMENTS_API_BASE_URL}/cnpj/${CNPJ}`)
          .then(response => {
            const {
              nome,
              fantasia,
              atividade_principal,
              qsa,
              logradouro,
              cep,
              numero,
              complemento,
              municipio,
              bairro,
              uf
            } = _.get(response, 'data.record', {})

            setValue('company_name', fantasia)
            setValue('business_type', _.get(atividade_principal, '0.text'))
            setValue('cep', cep)
            setValue('number', numero)
            setValue('complement', complemento)

            if (qsa && qsa.length == 1)
              setValue('resp_name', _.get(qsa, '0.nome'))
          })
          .catch(error => {
            console.log(error)
          })
      } else toastr.error('CNPJ inválido')
    }
  }, [watchCNPJ])

  return (
    <>
      <FormGroup
        name="cnpj"
        label="CNPJ"
        control={InputMask}
        mask="99999999999999"
        required
        solid
      />
      {validCNPJ && (
        <>
          <FormGroup
            name="company_name"
            label="Nome fantasia"
            control={FormControl}
            required
            solid
          />
          <FormGroup
            name="resp_name"
            label="Nome do responsável da empresa"
            control={FormControl}
            required
            solid
          />
          <FormGroup
            name="resp_cpf"
            label="CPF do responsável"
            control={InputMask}
            mask="99999999999"
            required
            solid
          />
          <FormGroup
            name="business_type"
            label="Descrição do negócio"
            control={FormControl}
            as="textarea"
            rows={2}
            required
            solid
          />
          <div className="mt-10">
            <h2 className="fw-bolder d-flex align-items-center text-dark mb-6">
              Endereço fiscal
            </h2>
            <AddressFields
              horizontal
              names={{
                zipCode: 'cep',
                address: 'street'
              }}
            />
          </div>
          <div className="mt-10">
            <div className="mb-6">
              <h2 className="fw-bolder d-flex align-items-center text-dark">
                Conta bancária
              </h2>
              <span className="text-muted fw-bolder fs-6">
                <span className="text-danger">Atenção!</span> A conta bancária
                precisa pertencer ao CNPJ cadastrado para que seja possível
                efetuar os saques, caso contrário os valores de venda poderão
                ficar bloqueados por tempo indeterminado
              </span>
            </div>
            <FormGroup
              name="bank"
              label="Banco"
              control={Select}
              options={banks.map(bank => ({
                label: bank.title,
                value: bank.slug
              }))}
              required
              solid
            />

            {watchBank && (
              <>
                <FormGroup
                  name="account_type"
                  label="Tipo de conta"
                  control={Select}
                  options={['Corrente', 'Poupança'].map(type => ({
                    label: type,
                    value: type
                  }))}
                  required
                  solid
                />
                <FormGroup
                  name="bank_ag"
                  label="Agência"
                  control={InputMask}
                  mask={bank.agencyMask}
                  required
                  solid
                />
                <FormGroup
                  name="bank_cc"
                  label="Número"
                  control={InputMask}
                  mask={bank.accountMask}
                  required
                  solid
                />
                <FormGroup
                  name="bank_check"
                  label="Confirmação de autenticidade"
                  control={FormCheck}
                  text="Confirmo que a conta bancária jurídica pertence ao CNPJ informado, e que a operadora financeira pode usá-la para transferir os valores faturados sob cadastro da empresa"
                  required
                  solid
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default VerifyAccountModalFields
