import { useRouter } from 'next/router'

import Invoice from '../domain/Invoices/Show'

const InvoicePage = () => {
  const router = useRouter()
  const { id } = router.query

  if (!id) return <></>

  return <Invoice id={id} />
}

export default InvoicePage
