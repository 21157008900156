import _ from 'lodash'
import { useSession } from 'next-auth/react'
import { useFormContext } from 'react-hook-form'
import { useWizard } from 'react-use-wizard'
import { PlansCard } from 'src/domain/Plans/Card'
import { PlansTablePricing } from 'src/domain/Plans/TablePricing'

import { useRecords, useResourceRecordContext } from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

export const SubscriptionsPlanFields = ({
  availablePlans,
  upgrade,
  downgrade,
  currentPlan,
  onHide
}) => {
  const { data: session } = useSession()
  const { record: subscription } = useResourceRecordContext()
  const { setValue } = useFormContext()
  const { nextStep } = useWizard()

  const handleSelect = plan => {
    setValue('plan_is_paid', plan.is_paid)
    setValue('plan_id', plan.id)

    if (!subscription) {
      setValue('kind', 'person')
      setValue('email', _.get(session, 'user.email'))
      setValue('name', _.get(session, 'user.name'))
    }

    nextStep()
  }

  return (
    <>
      <Modal.Header onHide={onHide} className="pb-0">
        <Modal.Icon
          className={`fad fa-${
            upgrade ? 'up' : downgrade ? 'down' : 'file-signature'
          } text-primary`}
        />
        <Modal.Title className="fw-bold">Assine já!</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="d-none d-lg-block">
          <PlansTablePricing
            plans={_.sortBy(availablePlans, ['ttzpay_price_cents'])}
            currentPlan={currentPlan}
            handleSelect={handleSelect}
          />
        </div>
        <div className="d-lg-none">
          <div className="row justify-content-center">
            {_.sortBy(availablePlans, ['ttzpay_price_cents']).map(plan => (
              <PlansCard
                key={plan.id}
                plan={plan}
                handleSelect={handleSelect}
                currentPlan={currentPlan}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
    </>
  )
}
