import React, { useState, useEffect } from 'react'

import { AddressFields } from '../../../../../../form'
import { useRecords } from '../../../../../../index'
import { usePaymentsContext } from '../../../../../../payments'
import Actions from '../Actions'

import Fields from './Fields'
import FormModal from './FormModal'

const validationSchema = AddressFields.getValidationSchema('payer.address')

const AccountStep = ({ Form, ...rest }) => {
  const [showFormModal, setShowFormModal] = useState(null)
  const { payerAddressesConfig, setBlocking } = usePaymentsContext()
  const {
    records: addresses,
    mutate: mutateAddresses,
    handleDelete: handleDeleteAddress,
    isLoading: isLoadingAddresses,
    isError: isErrorAddresses
  } = useRecords(payerAddressesConfig, { pagination: { perPage: 12 } })

  const enableAddresses = payerAddressesConfig && !isErrorAddresses
  const handleAdd = () => setShowFormModal({ show: true })
  const handleEdit = id => setShowFormModal({ show: true, id })
  const handleDelete = async id => {
    handleDeleteAddress(id).then(() => {
      mutateAddresses()
    })
  }

  useEffect(() => {
    setBlocking(isLoadingAddresses)
  }, [isLoadingAddresses])

  return (
    <>
      <div className="mb-10 text-center">
        <h1 className="mb-3">Endereço</h1>
        <div className="text-muted fw-bold fs-5">
          Este é seu endereço de cobrança, ao qual será associado a sua fatura
        </div>
      </div>
      <Form validationSchema={validationSchema}>
        <Fields
          enableAddresses={enableAddresses}
          addresses={addresses}
          handleEdit={handleEdit}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
        />
        <Actions {...rest} />
      </Form>

      {showFormModal && (
        <FormModal
          {...showFormModal}
          show={showFormModal}
          onHide={() => setShowFormModal(null)}
          onSave={() => {
            mutateAddresses()
            setShowFormModal(null)
          }}
          apiBasePath={payerAddressesConfig}
        />
      )}
    </>
  )
}

export default AccountStep
