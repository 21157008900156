import * as Sentry from '@sentry/nextjs'
// import { getServerSession } from 'next-auth/next'
import _ from 'lodash'

import { getErrorMessage } from '@tootz/react-admin'

import { tootzPaymentsApi } from './services/api'

const TootzPayments = async (req, res, options, authOptions) => {
  console.log('TootzPayments')
  // const session = await getServerSession(req, res, authOptions)

  // if (!session) return res.status(400).json({ error: 'Unauthorized' })

  const { payments, ...query } = req.query
  const body = req.body
  const cookies = req.cookies
  const headers = req.headers
  const method = req.method
  const error = query.error
  const { client } = options

  /**
   * Try to assign user to bugsnag
   */
  try {
    const user = JSON.parse(req.cookies?.user)

    Sentry.setUser(user)
  } catch (error) {}

  payments.unshift('accounts', process.env.TOOTZ_PAYMENTS_CLIENT_ACCOUNT_ID)

  const path = `/${payments.join('/')}`
  console.log(path)
  try {
    const forwardedRes = await tootzPaymentsApi({
      url: path,
      method,
      data: body,
      params: query,
      cookies,
      headers: {
        ...headers,
        // Authorization: process.env.TOOTZ_PAYMENTS_TOKEN_API,
        'X-Client-Token': process.env.TOOTZ_PAYMENTS_CLIENT_TOKEN
      }
    })

    return res.status(forwardedRes.status).json(forwardedRes.data)
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const status = _.get(error, 'response.status', 500)

    res.status(status).json(errorMessage)
  }
}

export default TootzPayments
