import React from 'react'

import Actions from '../Actions'

import Fields from './Fields'
import validationSchema from './validationSchema'

const BankSlipInInstallmentsStep = ({ Form, ...rest }) => {
  return (
    <>
      <div className="mb-10 text-center">
        <h1 className="mb-3">Método de pagamento</h1>
        <div className="text-muted fw-bold fs-5">
          Escolha a melhor forma de efetuar sua compra
        </div>
      </div>
      <Form validationSchema={validationSchema} className="min-h-400px">
        <Fields {...rest} />
        <Actions {...rest} />
      </Form>
    </>
  )
}

export default BankSlipInInstallmentsStep
