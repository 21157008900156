import { SELLING_MODES, MIN_PRICE_CENTS } from '../core/config'
import getTaxes from '../core/getTaxes'

const useTaxes = (sellingMode, netPriceCents) => {
  let taxes = {}

  if (SELLING_MODES.includes(sellingMode) && netPriceCents >= MIN_PRICE_CENTS)
    taxes = getTaxes(sellingMode, netPriceCents)

  return taxes
}

export default useTaxes
