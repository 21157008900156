import { createContext, useContext } from 'react'

import { DateTime } from 'luxon'
import { useRouter } from 'next/router'

import { useRecord, useSession } from '@tootz/react-admin'

import { SubscribeNowModal } from '@/domain/Subscriptions/SubscribeNowModal'

const SubscriptionContext = createContext()

const useSubscriptionContext = () => useContext(SubscriptionContext)

const isDev = process.env.NODE_ENV == 'development'

const SubscriptionProvider = ({ children }) => {
  const router = useRouter()
  const { pathname } = router

  const isRestrictedPage =
    pathname.includes('/busca') || pathname.includes('/documentos')

  const { data: session, status } = useSession()
  const loadingSession = status === 'loading'

  const {
    record: client,
    loadWrapperConfig: clientLoadWraperConfig,
    isLoading: isLoadingClient
  } = useRecord({
    r: '/client',
    forceIdPresence: false,
    shouldFetch: !loadingSession && session
  })

  const startedFreePlanAt = _.get(client, 'started_free_plan_at')
  const finishFreePlanAt = _.get(client, 'finish_free_plan_at')

  const {
    record: subscription,
    loadWrapperConfig: subscriptionLoadWraperConfig,
    isLoading: isLoadingSubscription
  } = useRecord({
    r: '/client/subscription',
    forceIdPresence: false,
    shouldFetch: !loadingSession && session
  })

  const isTrial = DateTime.fromISO(finishFreePlanAt) > DateTime.local()

  const canceledAt = _.get(subscription, 'canceled_at')
  const canceled = !!canceledAt

  const expiresAt = _.get(subscription, 'expires_at') || finishFreePlanAt
  const expired = DateTime.fromISO(expiresAt) < DateTime.local()

  const isActive = !!subscription && !expired

  const canUseIAFeatures =
    isTrial ||
    (!expired &&
      subscription?.plan?.slug == process.env.NEXT_PUBLIC_SUPERIOR_PLAN_SLUG)

  return (
    <SubscriptionContext.Provider
      value={{
        loadWraperConfig: {
          isLoading:
            clientLoadWraperConfig.isLoading ||
            subscriptionLoadWraperConfig.isLoading,
          isError:
            clientLoadWraperConfig.isError ||
            subscriptionLoadWraperConfig.isError,
          isEmpty:
            clientLoadWraperConfig.isEmpty ||
            subscriptionLoadWraperConfig.isEmpty
        },
        finishFreePlanAt,
        isTrial,
        subscription,
        canceled,
        canceledAt,
        expired,
        expiresAt,
        startedFreePlanAt,
        canUseIAFeatures
      }}
    >
      {children}
      {/* {showSubscribeNowModal && <SubscribeNowModal />} */}
    </SubscriptionContext.Provider>
  )
}

export { useSubscriptionContext, SubscriptionProvider }
