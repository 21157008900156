import { createContext, useContext, useState, useEffect } from 'react'

import _ from 'lodash'
import store from 'store2'

import {
  getPrice,
  getCartPrice,
  getPaymentMethods
} from '@tootz/react-admin/payments'
import { showErrorAlert } from '@tootz/react-admin'

import {
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  BANK_SLIP,
  PIX,
  ALL_PAYMENT_METHODS,
  CREDIT_CARD_MIN_INSTALLMENT_PRICE_CENTS,
  BANK_SLIP_MIN_INSTALLMENT_PRICE_CENTS,
  DEFAULT_MODE_FEE_PERCENT,
  MIN_PRICE_CENTS
} from '../core/config'

const PaymentsContext = createContext()

const usePaymentsContext = () => useContext(PaymentsContext)

const IUGU_JS_URL = 'https://js.iugu.com/v2'

const CART_ITEMS_STORE_KEY = '__payments_cartItems'
const ORDER_STORE_KEY = '__payments_order'
const PAYER_STORE_KEY = '__payments_payer'

const PaymentsProvider = ({ accountId: propAccountId, children }) => {
  const [accountId, setAccountId] = useState(propAccountId)
  const [cartItems, _setCartItems] = useState([])
  const [payer, _setPayer] = useState({})
  const [order, setOrder] = useState(null)
  const [blocking, setBlocking] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(ALL_PAYMENT_METHODS)
  const [creditCardMaxInstallments, setCreditCardMaxInstallments] = useState(12)
  const [
    creditCardMinInstallmentPriceCents,
    setCreditCardMinInstallmentPriceCents
  ] = useState(CREDIT_CARD_MIN_INSTALLMENT_PRICE_CENTS)
  const [bankSlipMaxInstallments, setBankSlipMaxInstallments] = useState(12)
  const [
    bankSlipMinInstallmentPriceCents,
    setBankSlipMinInstallmentPriceCents
  ] = useState(BANK_SLIP_MIN_INSTALLMENT_PRICE_CENTS)
  const [payerAddressesConfig, setPayerAddressesConfig] = useState({})
  const [payerCreditCardsConfig, setPayerCreditCardsConfig] = useState(null)

  const sellingMode = cartItems.find(
    cartItem => cartItem.selling_mode == 'manual'
  )
    ? 'manual'
    : 'default'
  const isDefaultMode = sellingMode == 'default'
  const isManualMode = sellingMode == 'manual'
  const maxInstallments = {
    [CREDIT_CARD]: {
      maxInstallments: creditCardMaxInstallments,
      minInstallmentPriceCents: creditCardMinInstallmentPriceCents
    },
    [BANK_SLIP_IN_INSTALLMENTS]: {
      maxInstallments: bankSlipMaxInstallments,
      minInstallmentPriceCents: bankSlipMinInstallmentPriceCents
    }
  }
  const cartPrice = getCartPrice(cartItems, maxInstallments)

  // Cart
  const cartIsEmpty = !cartItems || cartItems.length == 0
  const cartTotalPriceCents = cartPrice.cents
  const cartTotalNetPriceCents = cartItems.reduce(
    (sum, i) => sum + i.quantity * i.net_price_cents,
    0
  )
  const cartTotalItems = cartItems.reduce((sum, i) => sum + i.quantity, 0)
  const cartTotalUniqueItems = cartItems.length

  const _cleanUpCartItem = dirtyCartItem => {
    const ALLOWED_CART_ITEM_ATTRS = [
      'id',
      'description',
      'net_price_cents',
      'price_cents',
      'quantity',
      'selling_mode',
      'max_per_cart',
      'credit_card_max_installments',
      'bank_slip_max_installments',
      'meta'
    ]
    let cartItem = { ...dirtyCartItem }

    cartItem.max_per_cart = _.get(cartItem, 'max_quantity_per_person', 0)
    cartItem.quantity = _.get(cartItem, 'quantity', 1)
    cartItem.standalone =
      _.get(cartItem, 'standalone') ||
      _.get(cartItem, 'selling_mode') == 'manual'
    if (cartItem.max_per_cart) {
      cartItem.quantity = Math.min(cartItem.quantity, cartItem.max_per_cart)
    }
    const price = getPrice(
      _.get(cartItem, 'selling_mode', 'default'),
      cartItem.net_price_cents
    )
    cartItem.price_cents = price.cents

    // if (
    //   ![
    //     'id',
    //     'description',
    //     'net_price_cents',
    //     'price_cents',
    //     'quantity',
    //     'selling_mode',
    //     'max_per_cart'
    //   ].every(k => k in cartItem)
    // )
    //   throw Error(
    //     'Cart item must have at least id, description and net_price_cents attributes on Tootz Payments'
    //   )

    return _.pick(cartItem, ALLOWED_CART_ITEM_ATTRS)
  }
  const _cleanUpCartItems = dirtyCartItems =>
    dirtyCartItems.map(dirtyCartItem => _cleanUpCartItem(dirtyCartItem))

  const addCartItem = dirtyCartItem => {
    try {
      const cartItem = _cleanUpCartItem(dirtyCartItem)

      if (cartItem.standalone || _.find(cartItems, { standalone: true }))
        _setCartItems([cartItem])
      else {
        const cartItemsCopy = cartItems.slice()
        const cartItemIndex = cartItemsCopy.findIndex(i => i.id === cartItem.id)

        if (cartItemIndex !== -1)
          cartItemsCopy.splice(cartItemIndex, 1, cartItem)
        else cartItemsCopy.push(cartItem)

        _setCartItems(cartItemsCopy)
      }
    } catch (error) {
      showErrorAlert(error)
    }
  }
  const removeCartItem = id =>
    _setCartItems(prevItems => prevItems.filter(item => item.id !== id))
  const getCartItem = id => cartItems.find(i => i.id === id)
  const getCartItemQuantity = id => {
    const cartItem = getCartItem(id)

    if (cartItem) return cartItem.quantity
    else return 0
  }
  const updateCartItem = (id, cartItem) => addCartItem(cartItem)
  const updateCartItemQuantity = (id, quantity, cartItemToAddIfNotPresent) => {
    const cartItem = getCartItem(id)

    if (!cartItem) {
      if (cartItemToAddIfNotPresent) addCartItem(cartItemToAddIfNotPresent)
      return
    }
    if (_.isFunction(quantity)) quantity = quantity(getCartItemQuantity(id))

    try {
      if (quantity <= 0) removeCartItem(id)
      else if (
        _.has(cartItem, 'max_per_cart') &&
        cartItem.max_per_cart &&
        quantity > cartItem.max_per_cart
      ) {
        throw new Error('Quantidade máxima do item por carrinho atingida')
      } else {
        addCartItem({ ...cartItem, quantity })
      }
    } catch (error) {
      showErrorAlert(error)
    }
  }
  const emptyCart = () => _setCartItems([])
  const fastCheckout = dirtyCartItem => {
    const cartItem = _cleanUpCartItem(dirtyCartItem)

    _setCartItems([cartItem])
  }
  const setCartItems = (dirtyCartItems, showAlertWhenError = false) => {
    try {
      const cartItems = _cleanUpCartItems(dirtyCartItems)

      _setCartItems(cartItems)
    } catch (error) {
      if (showAlertWhenError) showErrorAlert(error)
    }
  }
  const inCart = id => !!getCartItem(id)

  const _getPaymentMethodsOptions = sellingMode => {
    const _paymentMethods = paymentMethods.filter(paymentMethod =>
      cartPrice.availablePaymentMethods.includes(paymentMethod)
    )

    const paymentMethodsOptions = [
      {
        label: 'Pix',
        value: PIX,
        description:
          sellingMode == 'default'
            ? `${(DEFAULT_MODE_FEE_PERCENT * 100).toFixed(
                0
              )}% de desconto e confirmação imediata`
            : 'Confirmação imediata',
        icon: 'fab fa-pix text-success'
      },
      {
        label: 'Boleto à vista',
        value: BANK_SLIP,
        description:
          sellingMode == 'default'
            ? `Pague com ${(DEFAULT_MODE_FEE_PERCENT * 100).toFixed(
                0
              )}% de desconto`
            : 'Aprovação em 1 ou dois dias úteis',
        icon: 'fad fa-memo-circle-check text-info'
      },
      {
        label: 'Cartão de crédito',
        value: CREDIT_CARD,
        description: `Pague parcelado em até ${
          cartPrice.maxCreditCardInstallments
        }x ${sellingMode == 'default' ? 'sem juros' : ''}`,
        icon: 'fad fa-credit-card text-primary'
      },
      {
        label: 'Boleto parcelado',
        value: BANK_SLIP_IN_INSTALLMENTS,
        description: `Boleto parcelado em até ${
          cartPrice.maxBankSlipInstallments
        }x ${sellingMode == 'default' ? 'sem juros' : ''}`,
        icon: 'fad fa-files text-warning'
      }
    ].filter(method => _paymentMethods.includes(method.value))

    return paymentMethodsOptions
  }

  const paymentMethodsOptions = _getPaymentMethodsOptions(
    sellingMode,
    maxInstallments
  )

  // Payer
  const setPayer = dirtyPayer => {
    if (!dirtyPayer) _setPayer({})
    if (!_.isObject(dirtyPayer)) return

    const newPayer = _.pick(dirtyPayer, [
      'name',
      'email',
      'cpf_cnpj',
      'phone_prefix',
      'phone'
    ])

    _setPayer(prevPayer => {
      if (!_.isEmpty(prevPayer) && prevPayer.email !== newPayer.email) {
        emptyCart()
      }

      return newPayer
    })
  }

  const clearPlayer = () => _setPayer({})

  const clearAllStore = () => {
    emptyCart()
    clearPlayer()
  }

  useEffect(() => {
    const script = document.createElement('script')

    script.src = IUGU_JS_URL
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (store.has(CART_ITEMS_STORE_KEY))
      setCartItems(store.get(CART_ITEMS_STORE_KEY), false)
    if (store.has(ORDER_STORE_KEY)) setOrder(store.get(ORDER_STORE_KEY))

    if (store.has(PAYER_STORE_KEY)) setPayer(store.get(PAYER_STORE_KEY))
  }, [])

  useEffect(() => {
    // Any change on cart items will reset the current order id
    setOrder(null)

    store.set(CART_ITEMS_STORE_KEY, cartItems)
  }, [cartItems])

  useEffect(() => {
    if (order) {
      const ALLOWED_ORDER_PARAMS = ['id', 'valid_until']
      store.set(ORDER_STORE_KEY, _.pick(order, ALLOWED_ORDER_PARAMS))
    } else store.remove(ORDER_STORE_KEY)
  }, [order])

  useEffect(() => {
    store.set(PAYER_STORE_KEY, payer)
  }, [payer])

  return (
    <PaymentsContext.Provider
      value={{
        accountId,
        setAccountId,

        cartItems,
        cartIsEmpty,
        cartTotalPriceCents,
        cartTotalNetPriceCents,
        cartTotalItems,
        cartTotalUniqueItems,
        addCartItem,
        removeCartItem,
        getCartItem,
        getCartItemQuantity,
        updateCartItem,
        updateCartItemQuantity,
        emptyCart,
        setCartItems,
        fastCheckout,
        inCart,

        paymentMethodsOptions,

        cartPrice,

        order,
        setOrder,

        payer,
        setPayer,

        payerAddressesConfig,
        setPayerAddressesConfig,

        payerCreditCardsConfig,
        setPayerCreditCardsConfig,

        setPaymentMethods,
        setCreditCardMaxInstallments,
        setCreditCardMinInstallmentPriceCents,
        setBankSlipMaxInstallments,
        setBankSlipMinInstallmentPriceCents,

        sellingMode,
        isDefaultMode,
        isManualMode,

        clearAllStore,

        PIX,
        BANK_SLIP,
        CREDIT_CARD,
        BANK_SLIP_IN_INSTALLMENTS,

        DEFAULT_MODE_FEE_PERCENT,

        blocking,
        setBlocking
      }}
    >
      {children}
    </PaymentsContext.Provider>
  )
}

export {
  usePaymentsContext,
  PaymentsProvider,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  BANK_SLIP,
  PIX
}
