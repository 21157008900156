import { useEffect } from 'react'

import cardValidator from 'card-validator'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { Dropdown } from '@tootz/react-admin/components'
import {
  FormGroup,
  FormControl,
  Select,
  CreditCardField,
  ErrorMessage
} from '@tootz/react-admin/form'
import { usePaymentsContext } from '@tootz/react-admin/payments'

import americanExpressSvg from '../../../../../../public/credit-cards/american-express.svg'
import dinersSvg from '../../../../../../public/credit-cards/diners.svg'
import eloSvg from '../../../../../../public/credit-cards/elo.png'
import mastercardSvg from '../../../../../../public/credit-cards/mastercard.svg'
import visaSvg from '../../../../../../public/credit-cards/visa.svg'

const CreditCardFields = ({
  enableCreditCards,
  creditCards,
  handleAdd,
  handleDelete,
  previousStep
}) => {
  const { watch, setValue } = useFormContext()
  const { cartPrice, CREDIT_CARD } = usePaymentsContext()

  const watchCreditCard = watch(CREDIT_CARD)
  const watchPaymentMethod = watch('payment_method')
  const installmentsOptions =
    cartPrice.getInstallmentsOptions(watchPaymentMethod)

  const handleSelectCreditCard = creditCard => {
    setValue(CREDIT_CARD, {
      ...creditCard,
      verification_value: ''
    })
  }

  useEffect(() => {
    if (creditCards && creditCards.length > 0) {
      const creditCard = _.find(creditCards, { default: true })

      if (creditCard) handleSelectCreditCard(creditCard)
    }
  }, [creditCards])

  return <>
    <span
      className="cursor-pointer d-flex align-items-center mb-3 text-muted text-hover-dark fw-bold"
      onClick={previousStep}
    >
      <i className="fad fa-long-arrow-left me-2"></i> Mudar método de
      pagamento
    </span>

    <FormGroup
      name="installments"
      label="Número de parcelas"
      control={Select}
      options={installmentsOptions.map(io => ({
        label: io.text,
        value: io.installments
      }))}
      isClearable={false}
      vertical
      solid
    />

    {enableCreditCards ? (
      <div className="mb-4">
        <div className="mh-225px min-h-200px scroll-y me-n7 pe-7 mb-4">
          {creditCards &&
            creditCards.map(creditCard => {
              const selected = watchCreditCard?.id == creditCard.id
              const creditCardValidation = cardValidator.number(
                creditCard.number.replace(/\D/g, '')
              )
              const creditCardFlags = {
                ['visa']: visaSvg,
                ['mastercard']: mastercardSvg,
                ['american-express']: americanExpressSvg,
                ['diners-club']: dinersSvg,
                ['elo']: eloSvg
              }
              const creditCardFlag =
                creditCardFlags[creditCardValidation?.card?.type]

              return (
                <div
                  key={creditCard.id}
                  className={`border border-hover-primary p-5 rounded-2 mb-4 cursor-pointer position-relative ${
                    selected ? 'border-primary' : ''
                  }`}
                  onClick={() => handleSelectCreditCard(creditCard)}
                >
                  {selected && (
                    <div className="position-absolute bottom-0 end-0 p-3 pe-4">
                      <i className="fad fa-check-circle text-primary fs-3"></i>
                    </div>
                  )}
                  <div className="d-flex align-items-center fs-6 fw-bolder mb-4 lh-1 text-uppercase">
                    {_.get(creditCard, 'full_name')}
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      {...creditCardFlag}
                      alt=""
                      className="me-4 h-40px w-60px obj-fit-contain"
                    />
                    <div>
                      <div className="fs-5 fw-bolder d-block mb-0">
                        {_.get(creditCardValidation, 'card.niceType')} ****{' '}
                        {_.get(creditCard, 'number').trim().split(' ').pop()}
                      </div>
                      <div className="fs-4 fw-bold d-block m-0 text-gray-400">
                        Expira em {_.get(creditCard, 'expiration')}
                      </div>
                    </div>
                    <div className="w-80px ms-4">
                      {selected && (
                        <FormControl
                          name="credit_card.verification_value"
                          placeholder="CVV"
                          size="sm"
                        />
                      )}
                    </div>
                  </div>
                  <div className="position-absolute top-0 end-0 p-1 d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle className="btn btn-icon bg-transparent btn-sm btn-active-light-primary">
                        <i className="fad fa-ellipsis-v"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Button
                          onClick={() => handleDelete(creditCard.id)}
                          className="btn-active-light-danger text-hover-danger"
                        >
                          <i className="fad fa-trash"></i>
                          Excluir
                        </Dropdown.Button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )
            })}
          {creditCards && !creditCards.length && (
            <>
              <span
                className="fw-bold bg-gray-100 py-4 px-6 rounded-2 d-block text-gray-700 cursor-pointer text-hover-primary d-flex flex-column align-items-center justify-content-center h-200px w-100 text-center"
                onClick={handleAdd}
              >
                Não encontramos nenhum cartão cadastrado, clique no botão
                abaixo para adicionar um novo{' '}
                <i className="m-2 fad fa-arrow-down text-primary"></i>
              </span>
              <ErrorMessage name="credit_card" />
            </>
          )}{' '}
        </div>
        <button
          type="button"
          className="btn btn-sm btn-light-primary d-block w-100"
          onClick={handleAdd}
        >
          + Novo cartão
        </button>
      </div>
    ) : (
      <>
        <CreditCardField namePrefix={CREDIT_CARD} />
      </>
    )}
  </>;
}

export default CreditCardFields
