import 'animate.css'
import '../../styles/main.scss'

import { useEffect } from 'react'

import TimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt'
import _ from 'lodash'
import { Settings } from 'luxon'
import mixpanel from 'mixpanel-browser'
import localFont from 'next/font/local'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { SessionProvider } from 'next-auth/react'
import { DefaultSeo } from 'next-seo'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { clarity } from 'react-microsoft-clarity'
import { ToastContainer } from 'react-toastify'
import 'animate.css'

import Hotjar from '@hotjar/browser'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import {
  BRAND_NAME,
  SITE_DESCRIPTION,
  SITE_URL,
  TWITTER_NAME
} from '../../react-admin.config'

import AuthGuard from '@/domain/AuthGuard'
import { api } from '@/services/api'

Settings.defaultZone = 'America/Sao_Paulo'
Settings.defaultLocale = 'pt-BR'

const GoogleAnalytics = () => (
  <>
    <Script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-YHNBZX6CVT"
    />
    <Script id="google-analytics">
      {`
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    function gtag(){dataLayer.push(arguments);}
    gtag('config', 'G-YHNBZX6CVT');
    `}
    </Script>
  </>
)

const graphikFont = localFont({
  variable: '--graphik-font',
  src: [
    { path: '../../public/fonts/Graphik/Graphik-Light.woff', weight: '300' },
    {
      path: '../../public/fonts/Graphik/Graphik-LightItalic.woff',
      weight: '300',
      style: 'italic'
    },
    { path: '../../public/fonts/Graphik/Graphik-Regular.woff', weight: '400' },
    {
      path: '../../public/fonts/Graphik/Graphik-RegularItalic.woff',
      weight: '400',
      style: 'italic'
    },
    { path: '../../public/fonts/Graphik/Graphik-Medium.woff', weight: '500' },
    {
      path: '../../public/fonts/Graphik/Graphik-MediumItalic.woff',
      weight: '500',
      style: 'italic'
    },
    { path: '../../public/fonts/Graphik/Graphik-Bold.woff', weight: '700' },
    {
      path: '../../public/fonts/Graphik/Graphik-BoldItalic.woff',
      weight: '700',
      style: 'italic'
    }
  ]
})

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await api.get(queryKey[0])
  return data
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      refetchIntervalInBackground: 60000,
      retry: true,
      refetchOnMount: true,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      queryFn: defaultQueryFn
    }
  }
})

const App = ({ Component, pageProps: { session, ...pageProps } }) => {
  const router = useRouter()
  const { pathname } = router
  const searchOrDocumentPage =
    pathname == '/busca' || pathname == '/documentos/[documentId]'

  useEffect(() => {
    // Google Tag Manager
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      dataLayer: {
        username: _.get(session, 'user.email', 'cloud@maestrialaw.com.br'),
        event: 'user-data-set'
      }
    })

    // React Time Ago
    TimeAgo.setDefaultLocale(pt)
    TimeAgo.addLocale(pt)

    // Hotjar
    Hotjar.init(3553290, 6)

    // Clarity
    clarity.init('jh8gd28756')

    // Resize Observer Polyfill
    if (typeof window !== 'undefined' && !window.ResizeObserver) install()

    // Mixpanel
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage'
    })
  }, [])

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - ${BRAND_NAME}`}
        defaultTitle={BRAND_NAME}
        description={SITE_DESCRIPTION}
        lang="pt_BR"
        openGraph={{
          type: 'website',
          url: SITE_URL,
          title: BRAND_NAME,
          description: SITE_DESCRIPTION,
          locale: 'pt_BR',
          site_name: BRAND_NAME
        }}
        facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
        twitter={{
          handle: TWITTER_NAME,
          site: TWITTER_NAME,
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/apple-touch-icon.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/favicon-16x16.png'
          },
          { rel: 'manifest', href: '/site.webmanifest' }
        ]}
        additionalMetaTags={[
          {
            name: 'msapplication-TileColor',
            content: '#ffffff'
          },
          {
            name: 'theme-color',
            content: '#ffffff'
          },
          {
            name: 'viewport',
            content: `width=${searchOrDocumentPage ? '1400' : 'device-width'}`
          }
        ]}
      />
      <style jsx global>{`
        :root {
          --graphik-font: ${graphikFont.style.fontFamily};
        }
      `}</style>
      {/* <GoogleAnalytics /> */}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CookiesProvider>
            <SessionProvider
              staleTime={5 * 60}
              clientMaxAge={5 * 60}
              refetchInterval={0}
              session={session}
            >
              <AuthGuard isPublic={Component.isPublic}>
                <Component {...pageProps} />
              </AuthGuard>
            </SessionProvider>
          </CookiesProvider>
        </Hydrate>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

export default App
