import _ from 'lodash'

export const clientKinds = [
  { slug: 'person', title: 'Pessoa Física' },
  { slug: 'company', title: 'Pessoa Jurídica' }
]
export const intervalTypes = [
  { slug: 'hours', title: 'Horas', singular: 'Hora' },
  { slug: 'days', title: 'Dias', singular: 'Dia' },
  { slug: 'weeks', title: 'Semanas', singular: 'Semana' },
  { slug: 'months', title: 'Meses', singular: 'Mês' },
  { slug: 'years', title: 'Meses', singular: 'Ano' }
]
export const paymentMethods = [
  { slug: 'credit_card', title: 'Cartão de Crédito' },
  { slug: 'bank_slip', title: 'Boleto' },
  { slug: 'pix', title: 'PIX' }
]
export const getClientKind = slug => _.find(clientKinds, { slug })
export const getIntervalType = slug => _.find(intervalTypes, { slug })
export const getPaymentMethod = slug => _.find(paymentMethods, { slug })

export const getConfig = (props = {}) => {
  const { accountId } = props
  const filter = {}

  const config = {
    apiBasePath: '/client/subscription',
    accountId,
    humanName: {
      singular: 'Assinatura',
      plural: 'Assinaturas'
    },
    filter,
    forceIdPresence: false,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false
    },
    clientKinds,
    getClientKind,
    intervalTypes,
    getIntervalType,
    paymentMethods,
    getPaymentMethod
  }
  return config
}
