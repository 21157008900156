import axios from 'axios'
import qs from 'qs'

// Payments API
const paymentsApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PAYMENTS_API_BASE_URL,
  headers: { accept: 'application/json' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

const tootzPaymentsApi = axios.create({
  baseURL: process.env.TOOTZ_PAYMENTS_API_BASE_URL,
  headers: { accept: 'application/json' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export { paymentsApi, tootzPaymentsApi }
