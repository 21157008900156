import { validateCPF, validateCNPJ } from 'validations-br'
import * as yup from 'yup'

import { AddressFields } from '@tootz/react-admin/form'

export const validationSchema = subscription => {
  const clientKind = subscription?.kind
  const addressValidationSchema = AddressFields.getValidationSchema(
    'location',
    {
      zipCode: 'zip_code',
      street: 'street',
      number: 'number',
      state: 'state',
      city: 'city',
      district: 'district',
      complement: 'complement'
    }
  )

  return yup
    .object()
    .shape({
      plan_id: yup.string().nullable().required().label('Plano'),
      kind: yup.string().nullable().required().label('Tipo'),

      name: yup
        .string()
        .nullable()
        .required()
        .test(
          'is-valid-name',
          'Por favor, insira nome completo sem abreviações',
          (value, { parent }) => {
            if (!value) return true

            const kind = clientKind || parent?.kind

            if (kind == 'person' && value) {
              const names = value.split(' ')

              if (
                names.find(
                  l => l.length == 1 || (l.length == 2 && l.includes('.'))
                )
              )
                return false

              if (names.length <= 1) return false
            }

            return true
          }
        )
        .label('Nome'),
      registration_number: yup
        .string()
        .nullable()
        .test('is-cpf-cnpj', 'Não é um CPF/CNPJ válido', (value, { parent }) =>
          (clientKind || parent?.kind) == 'person'
            ? validateCPF(value)
            : validateCNPJ(value)
        )
        .required()
        .label('CPF/CNPJ'),
      email: yup.string().email().nullable().required().label('Email'),
      full_phone: yup.string().nullable().required().label('Telefone')
    })
    .concat(addressValidationSchema)
}
