import { SELLING_MODES, MIN_PRICE_CENTS } from '../core/config'
import getPrice from '../core/getPrice'

const usePrice = (sellingMode, netPriceCents) => {
  let price = {}

  if (SELLING_MODES.includes(sellingMode) && netPriceCents >= MIN_PRICE_CENTS)
    price = getPrice(sellingMode, netPriceCents)

  return price
}

export default usePrice
