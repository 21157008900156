import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

import { CreditCardField } from '@tootz/react-admin/form'

yup.setLocale(pt)

const schema = yup.object().shape({
  installments: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .when(['payment_method'], {
      is: payment_method =>
        ['credit_card', 'bank_slip_in_installments'].includes(payment_method),
      then: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable()
        .required()
        .label('Nº de parcelas')
    })
})

export default schema
