import _ from 'lodash'

import { BANK_SLIP_IN_INSTALLMENTS } from '../config'

const _calcInstallmentsOptions = (
  cents,
  paymentMethod,
  inInstallmentsPrice,
  maxInstallments = 12
) => {
  // console.log(cents, paymentMethod, inInstallmentsPrice, maxInstallments)
  const paymentMethodMinInstallments =
    paymentMethod == BANK_SLIP_IN_INSTALLMENTS ? 2 : 1

  const installlmentsOptions = {}
  _.range(paymentMethodMinInstallments, maxInstallments + 1).forEach(
    installments => {
      const totalCents = _.get(
        inInstallmentsPrice,
        [installments, 'totalCents'],
        0
      )
      const installmentsPriceCents =
        paymentMethod == BANK_SLIP_IN_INSTALLMENTS
          ? Math.ceil(totalCents / installments)
          : Math.round(totalCents / installments)

      let text = `${installments}x ${new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(installmentsPriceCents / 100)}`

      if (totalCents > cents) text += ' (com juros)'
      else text += ' (sem juros)'

      installlmentsOptions[installments] = {
        installments,
        text,
        priceCents: installmentsPriceCents
      }
    }
  )

  // console.log(installlmentsOptions)

  return installlmentsOptions
}

export default _calcInstallmentsOptions
