import _ from 'lodash'

import { usePaymentsAccountContext } from '@tootz/react-admin/payments'

const useConfig = (props = {}) => {
  // const { accountId } = usePaymentsAccountContext()
  const { accountId } = props
  const filter = {}

  const intervalTypes = [
    { slug: 'weeks', title: 'Semanas' },
    { slug: 'months', title: 'Meses' }
  ]

  const paymentMethods = [
    // { slug: 'all', title: 'Todos' },
    { slug: 'credit_card', title: 'Cartão de Crédito' },
    { slug: 'bank_slip', title: 'Boleto' },
    { slug: 'pix', title: 'PIX' }
  ]

  const config = {
    apiBasePath: '/client/subscription',
    accountId,
    humanName: {
      singular: 'Assinatura',
      plural: 'Assinaturas'
    },
    filter,
    options: {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refetchInterval: 60000,
      shouldRetryOnError: true
    },
    intervalTypes,
    getIntervalType: slug => _.find(intervalTypes, { slug }),
    paymentMethods,
    getPaymentMethod: slug => _.find(paymentMethods, { slug })
  }
  return config
}

export default useConfig
