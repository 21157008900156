import {
  withResourceRecord,
  useResourceRecordContext
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'
import { CreditCardField } from '@tootz/react-admin/form'

const validationSchema = CreditCardField.getValidationSchema()

const UserCreditCardFormModal = ({ onHide, onSave }) => {
  const { Form, isSubmitting } = useResourceRecordContext()

  return (
    <Modal show onHide={onHide} centered>
      <Modal.Header onHide={onHide}>
        <Modal.Title>Adicionar Cartão de Crédito</Modal.Title>
      </Modal.Header>
      <Form onSave={onSave} validationSchema={validationSchema}>
        <Modal.Body>
          <CreditCardField showSaveAsDefault />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light mx-2" onClick={onHide}>
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary mx-2"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default withResourceRecord(UserCreditCardFormModal)
