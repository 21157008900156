import _ from 'lodash'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'

import {
  FormGroup,
  FormControl,
  FormSwitch,
  Select,
  MoneyInput
} from '@tootz/react-admin/form'

import getConfig from '../config'

const Fields = () => {
  const { intervalTypes, paymentMethods } = getConfig()
  const { control } = useFormContext()
  const {
    fields: features,
    append: appendFeature,
    remove: removeFeature
  } = useFieldArray({
    control,
    name: 'features'
  })
  const watchIsPay = useWatch({ name: 'is_paid' })
  const watchIsTrial = useWatch({ name: 'ttzpay_is_trial' })
  const watchHasIndicationReward = useWatch({ name: 'has_indication_reward' })

  return (
    <>
      <FormGroup
        name="identifier"
        label="Identificador"
        control={FormControl}
        solid
        required
      />
      <FormGroup
        name="name"
        label="Nome"
        control={FormControl}
        solid
        required
      />
      <FormGroup name="is_paid" label="É pago?" control={FormSwitch} solid />
      {!watchIsPay && (
        <FormGroup
          name="ttzpay_is_trial"
          label="É plano de teste?"
          control={FormSwitch}
          solid
          required
        />
      )}
      {watchIsPay && (
        <>
          <FormGroup
            name="ttzpay_price_cents"
            label="Valor"
            control={MoneyInput}
            solid
            required
          />
          <FormGroup name="ttzpay_trial" label="Período de gratuidade" solid>
            <div className="row">
              <div className="col-md-6">
                <FormControl
                  name="ttzpay_trial_interval"
                  label="Intervalo de teste"
                  type="number"
                  min={0}
                  defaultValue={0}
                  solid
                  required
                />
              </div>
              <div className="col-md-6">
                <Select
                  name="ttzpay_trial_interval_type"
                  options={intervalTypes.map(opt => ({
                    label: opt.title,
                    value: opt.slug
                  }))}
                  defaultValue="months"
                  solid
                  required
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup
            name="has_indication_reward"
            label="Possui recompensa por indicação?"
            control={FormSwitch}
            solid
          />
          {watchHasIndicationReward && (
            <FormGroup
              name="indication_reward"
              label="Recompensa por indicação"
              solid
              required
            >
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    name="indication_reward_interval"
                    type="number"
                    min={0}
                    defaultValue={0}
                    solid
                    required
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    name="indication_reward_interval_type"
                    options={intervalTypes.map(opt => ({
                      label: opt.title,
                      value: opt.slug
                    }))}
                    defaultValue="months"
                    solid
                    required
                  />
                </div>
              </div>
            </FormGroup>
          )}
        </>
      )}
      <FormGroup
        name="ttzpay_charge"
        label={watchIsTrial ? 'Período do plano' : 'Intervalo de cobrança'}
        solid
        required
      >
        <div className="row">
          <div className="col-md-6">
            <FormControl
              name="ttzpay_interval"
              type="number"
              min={1}
              defaultValue={1}
              solid
              required
            />
          </div>
          <div className="col-md-6">
            <Select
              name="ttzpay_interval_type"
              options={intervalTypes.map(opt => ({
                label: opt.title,
                value: opt.slug
              }))}
              defaultValue="months"
              solid
              required
            />
          </div>
        </div>
      </FormGroup>
      {watchIsPay && (
        <FormGroup
          name="ttzpay_payment_methods"
          label="Métodos de pagamento"
          control={Select}
          options={paymentMethods.map(opt => ({
            label: opt.title,
            value: opt.slug
          }))}
          defaultValue={paymentMethods.map(pm => pm.slug)}
          multiple
          solid
          required
        />
      )}

      {/* <FormGroup name="questions">
        <div className="d-flex align-items-center justify-content-between mb-4 mt-10">
          <h3 className="m-0">Benefícios</h3>
          <button
            className="btn btn-icon btn-sm btn-light-primary"
            type="button"
            onClick={() => appendFeature({})}
          >
            <i className="fad fa-plus"></i>
          </button>
        </div>
      </FormGroup>
      {features.map((feature, index) => (
        <div
          key={`features[${index}]`}
          className="border-1 border-gray-200 border-bottom-dashed mb-6"
        >
           <FormControl name={`features[${index}].id`} hidden />
          <FormGroup label="Nome" solid required>
            <div className="d-flex">
              <FormControl name={`features[${index}].name`} solid required />
              <button
                className="btn btn-icon btn-light-danger ms-2 flex-shrink-0"
                type="button"
                onClick={() => removeFeature(index)}
              >
                <i className="fad fa-minus"></i>
              </button>
            </div>
          </FormGroup>
          <FormGroup
            name={`features[${index}].identifier`}
            label="Identificador"
            control={FormControl}
            solid
            required
          />
          <FormGroup
            name={`features[${index}].priceCents`}
            label="Valor"
            control={MoneyInput}
            solid
            required
          />
        </div>
      ))} */}
    </>
  )
}

export default Fields
