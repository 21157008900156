import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { FormGroup, Select } from '../../../../../../form'
import { usePaymentsContext } from '../../../../../../payments'

const BankSlipInInstallmentsFields = ({ previousStep }) => {
  const { watch } = useFormContext()
  const { cartPrice } = usePaymentsContext()

  const watchPaymentMethod = watch('payment_method')
  const installmentsOptions =
    cartPrice.getInstallmentsOptions(watchPaymentMethod)

  return (
    <>
      <span
        className="cursor-pointer d-flex align-items-center mb-3 text-muted text-hover-dark fw-bold"
        onClick={previousStep}
      >
        <i className="fad fa-long-arrow-left me-2"></i> Mudar método de
        pagamento
      </span>

      <FormGroup
        name="installments"
        label="Número de parcelas"
        control={Select}
        options={installmentsOptions.map(io => ({
          label: io.text,
          value: io.installments
        }))}
        isClearable={false}
        vertical
        solid
      />
    </>
  )
}

export default BankSlipInInstallmentsFields
