const getConfig = () => {
  const statusOptions = [
    {
      slug: 'pending',
      name: 'Pendente pagamento',
      variant: 'warning',
      description: 'A fatura ainda não foi paga'
    },
    {
      slug: 'paid',
      name: 'Paga',
      variant: 'success',
      description:
        'PX realizado, boleto compensado ou transação capturada no cartão de crédito'
    },
    {
      slug: 'canceled',
      name: 'cancelada',
      variant: 'danger',
      description:
        'Fatura cancelada e não pode ser paga. Pode ter sido cancelada manualmente pelo painel ou comando via API, ou ainda, devido a uma falha de cobrança no cartão'
    },
    {
      slug: 'in_analysis',
      name: 'em análise',
      variant: 'info',
      description:
        'Recurso da iugu que acrescenta uma etapa a mais ao pagamento da sua fatura entre o (pending) e o (paid). Recurso opcional; você pode ativar/desativar a qualquer momento. (Válido para transação em duas etapas)'
    },
    {
      slug: 'draft',
      name: 'rascunho',
      variant: 'light',
      description: 'A fatura ainda não foi gerada, apenas os dados foram salvos'
    },
    {
      slug: 'partially_paid',
      name: 'parcialmente paga',
      variant: 'primary',
      description:
        'Quando um boleto não é pago em seu valor total, ou o valor de multa/juros não foi pago'
    },
    {
      slug: 'refunded',
      name: 'Reembolsada',
      variant: 'dark',
      description:
        'Quando você devolve o valor ao cliente por desistência ou por algum erro. Status válido penas para pagamentos com cartão de crédito e PIX.'
    },
    {
      slug: 'expired',
      name: 'expirada',
      variant: 'danger',
      description:
        'A cobrança atingiu o tempo limite após o vencimento e expirou. Não pode ser paga'
    },
    {
      slug: 'in_protest',
      name: 'em protesto',
      variant: 'dark',
      description:
        'Quando uma fatura já paga recebe uma notificação de não reconhecimento da compra (apenas para pagamentos por cartão de crédito)'
    },
    {
      slug: 'chargeback',
      name: 'contestada',
      variant: 'dark',
      description:
        'Quando o status (in_protest) é estornado para o cliente, pois ganhou a disputa do chargeback. Significa que sua fatura foi contestada e nesta etapa, já não poderá recorrer'
    }
  ]

  const paymentMethods = [
    { slug: 'bank_slip', name: 'Boleto bancário' },
    { slug: 'credit_card', name: 'Cartão de crédito' },
    { slug: 'pix', name: 'PIX' },
    { slug: 'all', name: 'Todos' }
  ]

  const config = {
    statusOptions,
    getStatus: slug => statusOptions.find(s => s.slug === slug),
    paymentMethods,
    getPaymentMethod: slug => paymentMethods.find(s => s.slug === slug)
  }

  return config
}

export default getConfig
