import { createContext, useContext, useState } from 'react'

const PaymentsAccountContext = createContext(null)

const usePaymentsAccountContext = () => useContext(PaymentsAccountContext)

const PaymentsAccountProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(null)

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show
        onHide={() => {
          onHide && onHide()
          setShowModal(null)
        }}
        onSave={record => {
          onSave && onSave(record)
          setShowModal(null)
        }}
      />
    )
  }

  return (
    <PaymentsAccountContext.Provider
      value={{
        showModal,
        setShowModal
      }}
    >
      {children}
      {showModal && <Modal {...showModal} />}
    </PaymentsAccountContext.Provider>
  )
}

const withPaymentsAccount = (Component, propConfig) => {
  const WrappedComponent = componentProps => {
    return (
      <PaymentsAccountProvider {...propConfig} {...componentProps}>
        <Component {...propConfig} {...componentProps} />
      </PaymentsAccountProvider>
    )
  }

  WrappedComponent.displayName = `withPaymentsAccount(${
    Component.displayName || Component.name
  })`

  return WrappedComponent
}

export {
  usePaymentsAccountContext,
  PaymentsAccountProvider,
  withPaymentsAccount
}
