import { useState } from 'react'

import {
  withResourceRecords,
  useResourceRecordsContext
} from '@tootz/react-admin'
import { Card, LoadWrapper } from '@tootz/react-admin/components'
import { FormControl } from '@tootz/react-admin/form'
import {
  usePaymentsAccountContext,
  withPaymentsAccount
} from '@tootz/react-admin/payments'

import EditModal from './EditModal'
import ListItem from './ListItem'
import NewModal from './NewModal'
import getConfig from './config'

const List = () => {
  const { setShowModal } = usePaymentsAccountContext()
  const {
    records,
    Pagination,
    isLoading,
    isError,
    isEmpty,
    FiltersForm,
    submitFiltersForm,
    mutate,
    config: { humanName }
  } = useResourceRecordsContext()

  const handleAdd = () =>
    setShowModal({
      component: NewModal,
      onSave: () => {
        mutate()
        setShowModal(false)
      }
    })

  const handleEdit = id =>
    setShowModal({
      component: EditModal,
      id,
      onSave: () => {
        mutate()
        setShowModal(false)
      }
    })

  return (
    <>
      <Card>
        <Card.Header noBorder className="pt-6">
          <Card.Title>
            <FiltersForm>
              <div className="d-flex align-items-center position-relative my-1">
                <i className="fad fa-search position-absolute ms-6" />
                <FormControl
                  name="title_cont"
                  placeholder="Busca textual"
                  solid
                  className="w-250px ps-14"
                />
              </div>
            </FiltersForm>
          </Card.Title>
          <Card.Toolbar>
            <button className="btn btn-primary" onClick={() => handleAdd()}>
              <i className="fad fa-plus"></i>
              Novo {humanName.singular}
            </button>
          </Card.Toolbar>
        </Card.Header>
        <Card.Body className="pt-2">
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 no-footer">
              <thead>
                <tr className="text-start text-gray-400 fw-bolder fs-4 text-uppercase gs-0">
                  <th className="">Nome</th>
                  <th className="">Métodos de pagamento</th>
                  <th className="">Intervalo de cobrança</th>
                  <th className="">Nº máx. de ciclos</th>
                  <th className="text-end">
                    Cobrar <br />
                    dias antes
                  </th>
                  <th className="text-end">Valor</th>
                  <th className="text-end pe-0 w-50px">Ações</th>
                </tr>
              </thead>
              <tbody className="fw-bold text-gray-600">
                <LoadWrapper
                  isLoading={isLoading}
                  isError={isError}
                  isEmpty={isEmpty}
                  tableCols={7}
                >
                  {records &&
                    records.map(record => (
                      <ListItem
                        key={record.id}
                        onEdit={() => handleEdit(record.id)}
                        {...record}
                      />
                    ))}
                </LoadWrapper>
              </tbody>
            </table>
          </div>
        </Card.Body>
        <Card.Footer>
          <Pagination />
        </Card.Footer>
      </Card>
    </>
  )
}

export default withResourceRecords(withPaymentsAccount(List), getConfig)
