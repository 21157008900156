import { getIntervalType } from './config'

export const PlansCard = ({ currentPlan, plan, handleSelect }) => {
  const intervalType = getIntervalType(plan.ttzpay_interval_type)
  const hasDiscountPrice =
    plan.ttzpay_discount_interval > 0 &&
    plan.ttzpay_price_with_discount_cents > 0

  return (
    <div key={plan.id} className="col-xl-6 mb-6">
      <div className="d-flex h-100 align-items-center">
        <div className="w-100 d-flex flex-column flex-center bg-light bg-opacity-75 py-15 px-10">
          <div className="mb-7 text-center">
            <h1 className="text-dark mb-5 fw-bolder">{plan.name}</h1>
            <div className="text-gray-600 fw-semibold mb-5 fs-8">
              A mais moderna tecnologia de busca (Semântica): encontre melhores
              decisões em menor tempo
            </div>
            {plan.is_paid ? (
              <div className="text-center">
                <div className="lh-sm">
                  <span
                    className={`mb-2 ${
                      hasDiscountPrice ? 'fs-9 text-gray-600' : 'text-primary'
                    }`}
                  >
                    R$
                  </span>
                  <span
                    className={`${
                      hasDiscountPrice
                        ? 'fs-1 text-gray-600 text-decoration-line-through'
                        : 'fs-3x text-primary'
                    } fw-bold`}
                  >
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      currencyDisplay: 'code',
                      maximumFractionDigits: 0
                    })
                      .format(plan.ttzpay_price_cents / 100)
                      .replace('BRL', '')
                      .trim()}
                  </span>
                  <span className="fs-7 fw-semibold opacity-50">
                    /
                    <span>
                      {intervalType
                        ? intervalType.singular
                        : plan.ttzpay_interval_type}
                    </span>
                  </span>
                </div>
                {hasDiscountPrice && (
                  <div className="lh-sm d-flex flex-column lh-sm">
                    <div>
                      <span className="mb-2 text-primary">R$</span>
                      <span className="fs-3x fw-bold text-primary">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          currencyDisplay: 'code',
                          maximumFractionDigits: 0
                        })
                          .format(plan.ttzpay_price_with_discount_cents / 100)
                          .replace('BRL', '')
                          .trim()}
                      </span>
                    </div>
                    <span className="fs-6 fw-semibold opacity-50">
                      <span>
                        {`no${plan.ttzpay_discount_interval > 1 && 's'} ${
                          plan.ttzpay_discount_interval
                        } primeiros ${getIntervalType(
                          plan.ttzpay_interval_type
                        )?.[
                          plan.ttzpay_discount_interval > 1
                            ? 'title'
                            : 'singular'
                        ].toLowerCase()}`}
                      </span>
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center">
                <span className="fs-3x fw-bold text-primary">Grátis</span>
              </div>
            )}
          </div>
          <div className="w-100 mb-10">
            {[
              'Busca semântica',
              'Filtros inteligentes',
              'Inteiro teor na tela',
              'Indicação inteligente de Súmulas e Repercussão Geral',
              'Estatísticas'
            ].map((benefit, index) => (
              <div
                key={`benefit-${index}`}
                className="d-flex align-items-center mb-5"
              >
                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                  {benefit}
                </span>
                <i className="fad fa-check-circle fs-2 text-success" />
              </div>
            ))}
          </div>

          {currentPlan && currentPlan.id == plan.id ? (
            <span className="btn btn-sm bg-gray-300">Plano Ativo</span>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleSelect(plan)}
            >
              Selecionar
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
