import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { FormGroup, PaymentMethodField } from '@tootz/react-admin/form'

import { usePaymentsContext } from '../../../../../../payments'

const PaymentMethodFields = () => {
  const { submitForm } = useFormContext()
  const { paymentMethodsOptions } = usePaymentsContext()

  return (
    <>
      <FormGroup
        name="payment_method"
        label="Método de pagamento"
        vertical
        required
      >
        <PaymentMethodField
          name="payment_method"
          label="Método de pagamento"
          options={paymentMethodsOptions}
          onChange={submitForm}
          vertical
        />
      </FormGroup>
    </>
  )
}

export default PaymentMethodFields
