import _ from 'lodash'

import banks from './banks.json'

const getConfig = () => {
  const autoAdvanceTypes = [
    { slug: 'daily', title: 'Diário' },
    { slug: 'weekly', title: 'Semanal' },
    { slug: 'monthly', title: 'Mensal' },
    { slug: 'days_after_payment', title: 'X dias depois do pagamento' }
  ]
  const getAutoAdvanceOptions = autoAdvanceType => {
    let options = []

    if (autoAdvanceType == 'weekly')
      options = [
        { slug: 0, title: 'Todo domingo' },
        { slug: 1, title: 'Toda segunda-feira' },
        { slug: 2, title: 'Toda terça-feira' },
        { slug: 3, title: 'Toda quarta-feira' },
        { slug: 4, title: 'Toda quinta-feira' },
        { slug: 5, title: 'Toda sexta-feira' },
        { slug: 6, title: 'Todo sábado' }
      ]
    else if (autoAdvanceType == 'monthly')
      options = _.range(1, 29).map(day => ({
        slug: day,
        title: `${day}° dia do mês`
      }))
    else if (autoAdvanceType == 'days_after_payment')
      options = _.range(1, 31).map(day => ({
        slug: day,
        title: `${day} dia${day > 1 ? 's' : ''} após o pagamento`
      }))

    return options
  }
  const priceRanges = [
    { slug: 'Até R$ 100,00', title: 'Até R$ 100,00' },
    {
      slug: 'Entre R$ 100,00 e R$ 500,00',
      title: 'Entre R$ 100,00 e R$ 500,00'
    },
    { slug: 'Mais que R$ 500,00', title: 'Mais que R$ 500,00' }
  ]

  const config = {
    basePath: '/accounts',
    apiBasePath: `${process.env.NEXT_PUBLIC_PAYMENTS_API_BASE_URL}/accounts`,
    humanName: { singular: 'Sub Conta', plural: 'Sub Contas' },
    header: {
      toolbarLinks: [
        {
          text: 'Adicionar',
          url: '/accounts/new',
          className: 'btn btn-light-primary btn-sm'
        }
      ]
    },
    autoAdvanceTypes,
    getAutoAdvanceType: slug => _.find(autoAdvanceTypes, { slug }),
    getAutoAdvanceOptions,
    getAutoAdvanceOption: (type, slug) =>
      _.find(autoAdvanceOptions(type), { slug }),
    priceRanges,
    getPriceRange: slug => _.find(priceRanges, { slug }),
    banks: _.sortBy(banks, 'title'),
    getBank: slug => _.find(banks, { slug })
  }

  return config
}

export default getConfig
