import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const PhoneInput = ({
  name,
  control,
  phonePrefixName = 'phone_prefix',
  phoneName = 'phone',
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const { register, setValue, getValues } = useFormContext()
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses(
    {
      size,
      solid,
      white,
      flush,
      shadow,
      className
    },
    !!errors[name] || invalid
  )
  const watchPhonePrefix = getValues(phonePrefixName)
  const watchPhone = getValues(phoneName)
  const fullPhone = `${watchPhonePrefix}${watchPhone}`.replace(/\D/g, '')

  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)

    const fullPhone = (e?.target?.value || '').replace(/\D/g, '')
    const phonePrefix = fullPhone.slice(0, 2)
    const phone = fullPhone.slice(2)

    setValue(phonePrefixName, phonePrefix)
    setValue(phoneName, phone)
  }

  useEffect(() => {
    register(phonePrefixName)
    register(phoneName)
  }, [])

  return (
    <ReactInputMask
      // maskChar={null}
      className={classes}
      value={fullPhone}
      onChange={handleChange}
      {...inputProps}
      {...rest}
      mask={
        fullPhone?.charAt(2) === '9' ? '(99) 9 9999-9999' : '(99) 9999-9999'
      }
    />
  )
}

const PhoneInputContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <PhoneInput name={name} control={control} {...rest} />
}

PhoneInputContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default PhoneInputContainer
