import _ from 'lodash'
import { useWatch } from 'react-hook-form'
import { useWizard } from 'react-use-wizard'

import { useResourceRecordContext } from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'
import {
  FormControl,
  ReferenceFields,
  AddressFields
} from '@tootz/react-admin/form'

import { SubscriptionClientFields } from './Client'
import { SubscriptionPaymentMethodFields } from './PaymentMethod'
import { validationSchema } from './validationSchema'

export const SubscriptionFields = ({ onHide }) => {
  const { isSubmitting } = useResourceRecordContext()
  const watchPlanIsPaid = useWatch({ name: 'plan_is_paid' })
  const { previousStep } = useWizard()

  return (
    <>
      <Modal.Header onHide={onHide}>
        <Modal.Title className="fw-bold">
          Preencha os dados abaixo para finalizar sua assinatura
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <FormControl name="plan_is_paid" hidden />
        <FormControl name="plan_id" hidden />
        <SubscriptionClientFields />
        <ReferenceFields value={['location']} />
        <AddressFields
          namePrefix="location"
          hideFieldsUntilZipCode
          names={{
            zipCode: 'zip_code',
            street: 'street',
            number: 'number',
            state: 'state',
            city: 'city',
            district: 'district',
            complement: 'complement'
          }}
        />
        {watchPlanIsPaid && <SubscriptionPaymentMethodFields />}
        <div className="d-flex justify-content-between w-100">
          <button
            type="button"
            className="btn btn-light"
            disabled={isSubmitting}
            onClick={() => previousStep()}
          >
            Trocar plano
          </button>
          <button className="btn btn-primary px-10" disabled={isSubmitting}>
            {isSubmitting ? 'Assinando...' : 'Assinar'}
          </button>
        </div>
      </Modal.Body>
    </>
  )
}

SubscriptionFields.validationSchema = validationSchema
