import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

const schema = yup.object().shape({
  payment_method: yup
    .string()
    .nullable()
    .required()
    .oneOf(['credit_card', 'bank_slip_in_installments', 'bank_slip', 'pix'])
    .label('Método de pagamento')
})

export default schema
