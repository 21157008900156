import React, { useState, useEffect } from 'react'

import { useRecords } from '../../../../../../index'
import { usePaymentsContext } from '../../../../../../payments'
import Actions from '../Actions'

import CreditCardFormModal from './CreditCardFormModal'
import Fields from './Fields'
import validationSchema from './validationSchema'

const CreditCardStep = ({ Form, ...rest }) => {
  const [showCreditCardFormModal, setShowCreditCardFormModal] = useState(null)
  const { payerCreditCardsConfig, setBlocking } = usePaymentsContext()
  const {
    records: creditCards,
    mutate: mutateCreditCards,
    handleDelete: handleDeleteCreditCard,
    isLoading: isLoadingCreditCard,
    isError: isErrorCreditCard
  } = useRecords(payerCreditCardsConfig, { pagination: { perPage: 12 } })

  const enableCreditCards = payerCreditCardsConfig && !isErrorCreditCard
  const handleAdd = () => setShowCreditCardFormModal({ show: true })
  const handleEdit = id => setShowCreditCardFormModal({ show: true, id })
  const handleDelete = async id => {
    handleDeleteCreditCard(id).then(() => {
      mutateCreditCards()
    })
  }

  useEffect(() => {
    setBlocking(isLoadingCreditCard)
  }, [isLoadingCreditCard])

  return (
    <>
      <div className="mb-10 text-center">
        <h1 className="mb-3">Método de pagamento</h1>
        <div className="text-muted fw-bold fs-5">
          Escolha a melhor forma de efetuar sua compra
        </div>
      </div>
      <Form validationSchema={validationSchema}>
        <Fields
          enableCreditCards={enableCreditCards}
          creditCards={creditCards}
          handleEdit={handleEdit}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          {...rest}
        />
        <Actions {...rest} />
      </Form>

      {showCreditCardFormModal && (
        <CreditCardFormModal
          {...showCreditCardFormModal}
          show={showCreditCardFormModal}
          onHide={() => setShowCreditCardFormModal(null)}
          onSave={() => {
            mutateCreditCards()
            setShowCreditCardFormModal(null)
          }}
          apiBasePath={payerCreditCardsConfig}
        />
      )}
    </>
  )
}

export default CreditCardStep
