import _ from 'lodash'

import {
  PIX,
  BANK_SLIP,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  DEFAULT_MODE_FEE_PERCENT
} from './config'
import getPrice from './getPrice'
import _calcInstallmentsOptions from './utils/_calcInstallmentsOptions'
import _calcMaxInstallments from './utils/_calcMaxInstallments'

const getManualModeCartPrice = (cartItems, maxInstallments) => {
  let cents = 0
  let inCashCents = 0
  let paymentMethods = {
    [PIX]: {},
    [BANK_SLIP]: {},
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  cartItems.forEach(cartItem => {
    const price = getPrice(
      'manual',
      cartItem.net_price_cents,
      cartItem.quantity
    )
    cents += price.cents
    inCashCents += price.inCashCents

    Object.keys(price.inInstallmentsCents[CREDIT_CARD]).forEach(installment => {
      _.set(
        paymentMethods[CREDIT_CARD],
        [installment, 'totalCents'],
        _.get(paymentMethods[CREDIT_CARD], [installment, 'totalCents'], 0) +
          price.inInstallmentsCents[CREDIT_CARD][installment]
      )
    })

    Object.keys(price.inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS]).forEach(
      installment => {
        _.set(
          paymentMethods[BANK_SLIP_IN_INSTALLMENTS],
          [installment, 'totalCents'],
          _.get(
            paymentMethods[BANK_SLIP_IN_INSTALLMENTS],
            [installment, 'totalCents'],
            0
          ) + price.inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS][installment]
        )
      }
    )
  })

  const maxCreditCardInstallments = _calcMaxInstallments(
    cents,
    _.get(maxInstallments, [CREDIT_CARD, 'minInstallmentPriceCents'], 0),
    _.get(maxInstallments, [CREDIT_CARD, 'maxInstallments'], 0),
    CREDIT_CARD
  )

  const maxBankSlipInstallments = _calcMaxInstallments(
    cents,
    _.get(
      maxInstallments,
      [BANK_SLIP_IN_INSTALLMENTS, 'minInstallmentPriceCents'],
      0
    ),
    _.get(maxInstallments, [BANK_SLIP_IN_INSTALLMENTS, 'maxInstallments'], 0),
    BANK_SLIP_IN_INSTALLMENTS
  )

  paymentMethods[PIX] = {
    totalCents: inCashCents
  }
  paymentMethods[BANK_SLIP] = {
    totalCents: inCashCents
  }
  paymentMethods[CREDIT_CARD] = _.pickBy(
    _.merge(
      paymentMethods[CREDIT_CARD],
      _calcInstallmentsOptions(cents, CREDIT_CARD, paymentMethods[CREDIT_CARD])
    ),
    ({ installments }) => installments <= maxCreditCardInstallments
  )
  paymentMethods[BANK_SLIP_IN_INSTALLMENTS] = _.pickBy(
    _.merge(
      paymentMethods[BANK_SLIP_IN_INSTALLMENTS],
      _calcInstallmentsOptions(
        cents,
        BANK_SLIP_IN_INSTALLMENTS,
        paymentMethods[BANK_SLIP_IN_INSTALLMENTS]
      )
    ),
    ({ installments }) => installments <= maxBankSlipInstallments
  )

  let availablePaymentMethods = [PIX, BANK_SLIP]
  if (Object.keys(paymentMethods[CREDIT_CARD]).length)
    availablePaymentMethods.push(CREDIT_CARD)
  if (Object.keys(paymentMethods[BANK_SLIP_IN_INSTALLMENTS]).length)
    availablePaymentMethods.push(BANK_SLIP_IN_INSTALLMENTS)

  const getPaymentMethodCents = (paymentMethod, installments) => {
    if ([PIX, BANK_SLIP].includes(paymentMethod))
      return _.get(paymentMethods, [paymentMethod, 'totalCents'])
    else if ([CREDIT_CARD, BANK_SLIP_IN_INSTALLMENTS].includes(paymentMethod))
      return _.get(paymentMethods, [paymentMethod, installments, 'totalCents'])
  }

  const getInstallmentsOptions = paymentMethod => {
    if (CREDIT_CARD == paymentMethod)
      return Object.values(
        _.pickBy(
          _.get(paymentMethods, paymentMethod),
          ({ installments }) => installments <= maxCreditCardInstallments
        )
      )
    else if (BANK_SLIP_IN_INSTALLMENTS == paymentMethod)
      return Object.values(
        _.pickBy(
          _.get(paymentMethods, paymentMethod),
          ({ installments }) => installments <= maxBankSlipInstallments
        )
      )
    else return []
  }

  return {
    cents,
    saleCents: null,
    saleDiscountType: null,
    saleDiscount: null,
    inCashCents,
    inCashCentsDiscountType: null,
    inCashCentsDiscount: null,
    maxCreditCardInstallments,
    maxBankSlipInstallments,
    availablePaymentMethods,
    paymentMethods,

    getPaymentMethodCents,
    getInstallmentsOptions
  }
}

const getDefaultModeCartPrice = (cartItems, maxInstallments) => {
  let cents = 0
  let saleCents = 0
  let inCashCents = 0
  let paymentMethods = {
    [PIX]: {},
    [BANK_SLIP]: {},
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  cartItems.forEach(cartItem => {
    const price = getPrice(
      'default',
      cartItem.net_price_cents,
      cartItem.quantity
    )
    cents += price.cents
    saleCents += price.saleCents
    inCashCents += price.inCashCents

    Object.keys(price.inInstallmentsCents[CREDIT_CARD]).forEach(installment => {
      _.set(
        paymentMethods[CREDIT_CARD],
        [installment, 'totalCents'],
        _.get(paymentMethods[CREDIT_CARD], [installment, 'totalCents'], 0) +
          price.inInstallmentsCents[CREDIT_CARD][installment]
      )
    })

    Object.keys(price.inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS]).forEach(
      installment => {
        _.set(
          paymentMethods[BANK_SLIP_IN_INSTALLMENTS],
          [installment, 'totalCents'],
          _.get(
            paymentMethods[BANK_SLIP_IN_INSTALLMENTS],
            [installment, 'totalCents'],
            0
          ) + price.inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS][installment]
        )
      }
    )
  })

  const maxCreditCardInstallments = _calcMaxInstallments(
    cents,
    _.get(maxInstallments, [CREDIT_CARD, 'minInstallmentPriceCents'], 0),
    _.get(maxInstallments, [CREDIT_CARD, 'maxInstallments'], 0),
    CREDIT_CARD
  )

  const maxBankSlipInstallments = _calcMaxInstallments(
    cents,
    _.get(
      maxInstallments,
      [BANK_SLIP_IN_INSTALLMENTS, 'minInstallmentPriceCents'],
      0
    ),
    _.get(maxInstallments, [BANK_SLIP_IN_INSTALLMENTS, 'maxInstallments'], 0),
    BANK_SLIP_IN_INSTALLMENTS
  )

  paymentMethods[PIX] = {
    totalCents: inCashCents
  }
  paymentMethods[BANK_SLIP] = {
    totalCents: inCashCents
  }
  paymentMethods[CREDIT_CARD] = _.pickBy(
    _.merge(
      paymentMethods[CREDIT_CARD],
      _calcInstallmentsOptions(cents, CREDIT_CARD, paymentMethods[CREDIT_CARD])
    ),
    item => item.installments <= maxCreditCardInstallments
  )
  paymentMethods[BANK_SLIP_IN_INSTALLMENTS] = _.pickBy(
    _.merge(
      paymentMethods[BANK_SLIP_IN_INSTALLMENTS],
      _calcInstallmentsOptions(
        cents,
        BANK_SLIP_IN_INSTALLMENTS,
        paymentMethods[BANK_SLIP_IN_INSTALLMENTS]
      )
    ),
    ({ installments }) => installments <= maxBankSlipInstallments
  )

  let availablePaymentMethods = [PIX, BANK_SLIP]
  if (Object.keys(paymentMethods[CREDIT_CARD]).length)
    availablePaymentMethods.push(CREDIT_CARD)
  if (Object.keys(paymentMethods[BANK_SLIP_IN_INSTALLMENTS]).length)
    availablePaymentMethods.push(BANK_SLIP_IN_INSTALLMENTS)

  const getPaymentMethodCents = (paymentMethod, installments) => {
    if ([PIX, BANK_SLIP].includes(paymentMethod))
      return _.get(paymentMethods, [paymentMethod, 'totalCents'])
    else if ([CREDIT_CARD, BANK_SLIP_IN_INSTALLMENTS].includes(paymentMethod))
      return _.get(paymentMethods, [paymentMethod, installments, 'totalCents'])
  }

  const getInstallmentsOptions = paymentMethod => {
    if (CREDIT_CARD == paymentMethod)
      return Object.values(
        _.pickBy(
          _.get(paymentMethods, paymentMethod),
          ({ installments }) => installments <= maxCreditCardInstallments
        )
      )
    else if (BANK_SLIP_IN_INSTALLMENTS == paymentMethod)
      return Object.values(
        _.pickBy(
          _.get(paymentMethods, paymentMethod),
          ({ installments }) => installments <= maxBankSlipInstallments
        )
      )
    else return []
  }

  return {
    cents,
    saleCents,
    saleDiscountType: 'percent',
    saleDiscount: DEFAULT_MODE_FEE_PERCENT,
    inCashCents,
    inCashCentsDiscountType: 'percent',
    inCashCentsDiscount: DEFAULT_MODE_FEE_PERCENT,
    maxCreditCardInstallments,
    maxBankSlipInstallments,
    availablePaymentMethods,
    paymentMethods,

    getPaymentMethodCents,
    getInstallmentsOptions
  }
}

const getCartPrice = (cartItems = [], maxInstallments) => {
  const sellingMode = cartItems.find(
    cartItem => cartItem.selling_mode == 'manual'
  )
    ? 'manual'
    : 'default'

  const [isDefaultMode, isManualMode] = [
    sellingMode == 'default',
    sellingMode == 'manual'
  ]

  let sellingModePrice = {}
  if (isManualMode)
    sellingModePrice = getManualModeCartPrice(cartItems, maxInstallments)
  else sellingModePrice = getDefaultModeCartPrice(cartItems, maxInstallments)

  return { sellingMode, isDefaultMode, isManualMode, ...sellingModePrice }
}

export default getCartPrice
export { getManualModeCartPrice, getDefaultModeCartPrice }
