import { useEffect } from 'react'

import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { Dropdown } from '@tootz/react-admin/components'
import { AddressFields, ErrorMessage } from '@tootz/react-admin/form'
import { usePaymentsContext } from '@tootz/react-admin/payments'

const validationSchema = AddressFields.getValidationSchema('payer.address')

const AddressStepFields = ({
  enableAddresses,
  addresses,
  handleEdit,
  handleAdd,
  handleDelete
}) => {
  const { watch, setValue } = useFormContext()

  const watchAddress = watch('payer.address')

  const validateAddress = async address =>
    await validationSchema.validate({
      payer: {
        address
      }
    })
  const handleSelectAdddress = async address => {
    try {
      await validateAddress(address)
      setValue('payer.address', address)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const address = _.find(addresses, { default: true })

      if (address) {
        try {
          validateAddress(address)
          setValue('payer.address', address)
        } catch (error) {
          console.log(error)
        }
      }
    }
  }, [addresses])

  return (
    <>
      {enableAddresses && (
        <>
          <div className="mh-350px min-h-300px scroll-y me-n7 pe-7 mb-4">
            {_.reverse(_.sortBy(addresses, 'id')).map(address => {
              const selectedAddress = watchAddress?.id == address.id

              return (
                <div
                  key={address.id}
                  className={`border border-hover-primary py-5 px-6 pe-10 rounded-2 mb-4 cursor-pointer position-relative ${
                    selectedAddress ? 'border-primary' : ''
                  }`}
                  onClick={() => handleSelectAdddress(address)}
                >
                  {selectedAddress && (
                    <div className="position-absolute bottom-0 end-0 p-3 pe-4">
                      <i className="fad fa-check-circle text-primary fs-3"></i>
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="fs-5 fw-bolder d-block text-capitalize mb-1">
                        {address.street}, {address.number}
                      </div>
                      {address.complement && (
                        <div className="fs-4 fw-bold text-gray-400 text-capitalize d-block">
                          {address.complement}
                        </div>
                      )}
                      <div className="fs-4 fw-bold text-gray-400 text-capitalize d-block">
                        {address.district}, {address.city}/{address.state}
                      </div>
                      <div className="fs-5 fw-bolder text-gray-400 d-block">
                        {address.zip_code}
                      </div>
                    </div>
                  </div>
                  <div className="position-absolute top-0 end-0 p-1 d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle className="btn btn-icon bg-transparent btn-sm btn-active-light-primary">
                        <i className="fad fa-ellipsis-v"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Button onClick={() => handleEdit(address.id)}>
                          <i className="fad fa-edit"></i>
                          Editar
                        </Dropdown.Button>
                        <Dropdown.Divider />
                        <Dropdown.Button
                          onClick={() => {
                            handleDelete(address.id)
                            setValue('payer.address', {})
                          }}
                          className="btn-active-light-danger text-hover-danger"
                        >
                          <i className="fad fa-trash"></i>
                          Excluir
                        </Dropdown.Button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )
            })}
            {addresses && !addresses.length && (
              <>
                <span
                  className="fw-bold bg-gray-100 py-4 px-6 rounded-2 d-block text-gray-700 cursor-pointer text-hover-primary d-flex flex-column align-items-center justify-content-center h-300px w-100 text-center"
                  onClick={handleAdd}
                >
                  Não encontramos nenhum endereço cadastrado, clique no botão
                  abaixo para adicionar um novo{' '}
                  <i className="m-2 fad fa-arrow-down text-primary"></i>
                </span>
                <ErrorMessage name="payer.address" />
              </>
            )}
          </div>
          <button
            type="button"
            className="btn btn-sm btn-light-primary"
            onClick={handleAdd}
          >
            + Novo endereço
          </button>
        </>
      )}

      {!enableAddresses && <AddressFields namePrefix="payer.address" />}
    </>
  )
}

export default AddressStepFields
