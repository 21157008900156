import getCartPrice from '../core/getCartPrice'

const useCartPrice = (cartItems = [], maxInstallments) => {
  let cartPrice = {}

  if (cartItems && cartItems.length > 0)
    cartPrice = getCartPrice(cartItems, maxInstallments)

  return cartPrice
}

export default useCartPrice
