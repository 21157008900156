import _ from 'lodash'

import {
  withResourceRecords,
  useResourceRecordsContext
} from '@tootz/react-admin'
import { Card, LoadWrapper } from '@tootz/react-admin/components'

import PaymentsWithdrawalListItem from './ListItem'
import getConfig from './config'

const PaymentsWithdrawals = () => {
  const {
    records: withdrawals,
    FiltersForm,
    Pagination,
    isLoading,
    isError,
    isEmpty
  } = useResourceRecordsContext()

  return (
    <Card>
      <Card.Body>
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 no-footer">
            <thead>
              <tr className="text-start text-gray-400 fw-bolder fs-4 text-uppercase gs-0">
                <th className="min-w-100px">Solicitação</th>
                <th className="min-w-100px">Previsão</th>
                <th className="text-end min-w-100px">Valor</th>
                <th className="text-center w-150px">Status</th>
                <th className="text-end w-200px">Banco</th>
              </tr>
            </thead>
            <tbody className="fw-bold text-gray-600">
              <LoadWrapper
                isLoading={isLoading}
                isError={isError}
                isEmpty={isEmpty}
                tableCols={5}
              >
                {withdrawals &&
                  withdrawals.map(withdrawal => (
                    <PaymentsWithdrawalListItem
                      key={withdrawal.id}
                      {...withdrawal}
                    />
                  ))}
              </LoadWrapper>
            </tbody>
          </table>
        </div>
      </Card.Body>
      <Card.Footer>
        <Pagination />
      </Card.Footer>
    </Card>
  )
}

export default withResourceRecords(PaymentsWithdrawals, getConfig)
