import { useEffect } from 'react'

import { useWatch } from 'react-hook-form'

import {
  FormGroup,
  FormControl,
  BtnGroupRadio,
  CreditCardField
} from '@tootz/react-admin/form'

const IUGU_JS_URL = 'https://js.iugu.com/v2'
const ONLY_CREDIT_CARD_ALLOWED = true

export const SubscriptionPaymentMethodFields = () => {
  const watchPaymentMethod = useWatch({
    name: 'default_payment_method',
    defaultValue: 'credit_card'
  })

  useEffect(() => {
    const script = document.createElement('script')

    script.src = IUGU_JS_URL
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (ONLY_CREDIT_CARD_ALLOWED)
    return (
      <>
        <span className="fw-bold d-block mb-4">Dados do Cartão</span>
        <FormControl
          name="default_payment_method"
          defaultValue="credit_card"
          hidden
        />
        <CreditCardField />
      </>
    )
  return (
    <>
      <div className="w-100 d-flex align-items-center justify-content-center mb-3">
        <BtnGroupRadio
          name="default_payment_method"
          defaultValue="credit_card"
          options={[
            { label: 'Cartão de crédito', value: 'credit_card' },
            { label: 'Boleto', value: 'bank_slip' },
            { label: 'PIX', value: 'pix' }
          ]}
        />
      </div>
      {watchPaymentMethod == 'credit_card' && <CreditCardField />}
      {['bank_slip', 'pix'].includes(watchPaymentMethod) && (
        <>
          <FormGroup
            name="cc_emails"
            label="E-mail"
            control={FormControl}
            type="email"
            required
            vertical
          />
          <FormGroup
            name="phone"
            label="Telefone"
            control={FormControl}
            required
            vertical
          />
        </>
      )}
    </>
  )
}
