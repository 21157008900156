import { FormGroup, FormControl, InputMask } from '@tootz/react-admin/form'

const AccountStepFields = () => {
  return (
    <>
      <FormGroup
        name="payer.name"
        label="Nome completo"
        control={FormControl}
        vertical
        solid
        required
      />
      <FormGroup
        name="payer.email"
        label="E-mail"
        control={FormControl}
        type="email"
        vertical
        solid
        required
      />
      <FormGroup
        name="payer.cpf_cnpj"
        label="CPF"
        control={InputMask}
        mask={'999.999.999-99'}
        vertical
        solid
        required
      />
      <div className="row">
        <div className="col-4">
          <FormGroup
            name="payer.phone_prefix"
            label="DDD"
            control={InputMask}
            mask={'(999)'}
            vertical
            solid
            required
          />
        </div>
        <div className="col-8">
          <FormGroup
            name="payer.phone"
            label="Telefone"
            control={InputMask}
            mask={'9 9999-9999'}
            vertical
            solid
            required
          />
        </div>
      </div>
    </>
  )
}

export default AccountStepFields
