import _ from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Card, Dropdown, Symbol } from '@tootz/react-admin/components'
import { usePrice } from '@tootz/react-admin/payments'

import getConfig from './config'

const PaymentsWithdrawalListItem = ({
  id,
  status: statusSlug,
  created_at,
  updated_at,
  reference,
  feedback,
  paying_at,
  amount,
  account_name,
  account_id,
  bank_address,
  custom_variables
}) => {
  const { getStatus } = getConfig()
  const status = getStatus(statusSlug)

  return (
    <tr>
      <td>
        {DateTime.fromISO(created_at).toLocaleString(DateTime.DATETIME_MED)}
      </td>
      <td>{DateTime.fromISO(paying_at).toLocaleString(DateTime.DATE_MED)}</td>
      <td className="text-end pe-0">
        <span className="fw-bolder text-dark">{amount}</span>
      </td>
      <td className="text-center">
        {status && (
          <div className={`badge badge-light-${status.variant}`}>
            {status.title}
          </div>
        )}
      </td>
      <td className="fs-5 text-end pe-0">
        {bank_address.bank}
        <br />{' '}
        {bank_address.account_type.toLowerCase() == 'corrente' ? 'CC' : 'CP'}:
        {bank_address.bank_ag} AG:
        {bank_address.bank_cc}
      </td>
    </tr>
  )
}

export default PaymentsWithdrawalListItem
